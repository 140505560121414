import _ from 'lodash';
import * as Constant from './constant';
import LocalizedString from './localization';


export const validEmail = (email) => {
  // eslint-disable-next-line
    const regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return email ? email.match(regexMail) : false;
};

export const validPhoneNumber = (phoneNumber) => {
  const regexPhone = /^((\+[1-9]{1,2})|[0])[1-9][0-9]{3,10}$/;
  return phoneNumber ? phoneNumber.match(regexPhone) : false;
};

export const validatePasswordComplexity = (password) => {
  if (!password || password.length < Constant.PASSWORD_MIN_LENGTH) {
    throw new Error(LocalizedString.common.errMsgMinPasswordLength);
  }
};

const rxFormValidateRequiredFields = (values, requiredFields) => {
  const errors = {};
  requiredFields.forEach((field) => {
    if (!values[field]) { errors[field] = LocalizedString.common.errMsgRequired; }
  });
  return errors;
};

export const rxformValidateChangePassword = (values) => {
  const requiredFields = [
    Constant.RXFIELD_OLD_PASSWORD,
    Constant.RXFIELD_NEW_PASSWORD,
    Constant.RXFIELD_RE_NEW_PASSWORD,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.newPassword !== values.reNewPassword) {
    errors.reNewPassword = LocalizedString.common.errMsgPasswordDoesNotMatch;
  }

  return errors;
};

export const rxformValidateResetPassword = (values) => {
  const requiredFields = [
    Constant.RXFIELD_PASSWORD,
    Constant.RXFIELD_REPASSWORD,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.password !== values.repassword) {
    errors.repassword = LocalizedString.common.errMsgPasswordDoesNotMatch;
  }

  return errors;
};

export const rxformValidateProfileInfo = (values) => {
  const requiredFields = [
    Constant.RXFIELD_FULLNAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  // if (!validPhoneNumber(values.phone)) {
  //   errors.phone = LocalizedString.common.errMsgInvalidPhoneNumberFormat;
  // }
  return errors;
};

export const rxformValidateRegistrationInfo = (values) => {
  const requiredFields = [
    Constant.RXFIELD_FULLNAME,
    Constant.RXFIELD_PHONE,
    Constant.RXFIELD_JOB_TITLE,
    Constant.RXFIELD_PASSWORD,
    Constant.RXFIELD_REPASSWORD,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!validPhoneNumber(values.phone)) {
    errors.phone = LocalizedString.common.errMsgInvalidPhoneNumberFormat;
  }

  if (values.password !== values.repassword) {
    errors.repassword = LocalizedString.common.errMsgPasswordDoesNotMatch;
  }
  return errors;
};

export const rxformValidateContactUsMessage = (values) => {
  const requiredFields = [
    Constant.RXFIELD_SUBJECT,
    Constant.RXFIELD_MESSAGE,
  ];

  return rxFormValidateRequiredFields(values, requiredFields);
};

export const validateRegistrationInfo = (registrationInfo) => {
  const error = rxformValidateRegistrationInfo(registrationInfo);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const validateProfileInfo = (profileInfo) => {
  const error = rxformValidateProfileInfo(profileInfo);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const validatePin = (pin) => {
  const matchesPattern = /[0-9]{6}/.test(pin);

  if (pin.length !== Constant.PIN_LENGTH || !matchesPattern) {
    throw new Error(LocalizedString.pinVerificationScreen.errMsgPinMustBeSixDigits);
  }
};

export const validateContactUsMessage = (subject, message) => {
  const error = rxformValidateContactUsMessage({ subject, message });
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};


export const rxformValidateBookFeeder = (values, isExport) => {
  const requiredFields = [
    Constant.RXFIELD_BL_NO,
    Constant.RXFIELD_PAYMENT_ON_BEHALF_OF,
    // Constant.RXFIELD_DO_NO,
    Constant.RXFIELD_DO_EXPIRED,
    Constant.RXFIELD_EMPTY_DEPO,
    Constant.RXFIELD_AREA,
    Constant.RXFIELD_WAREHOUSE,
    Constant.RXFIELD_PLAN_DATE,
  ];

  if (!isExport) {
    requiredFields.push(Constant.RXFIELD_SPC_DATE);
  }

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateBookFeeder = (bookFeeder, isExport) => {
  const error = rxformValidateBookFeeder(bookFeeder, isExport);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const rxformValidateBookExport = (values) => {
  const requiredFields = [
    Constant.RXFIELD_DO_NO,
    // Constant.RXFIELD_DO_DATE,
    // Constant.RXFIELD_PEB_NO,
    // Constant.RXFIELD_PEB_DATE,
    // Constant.RXFIELD_VESSEL,
    // Constant.RXFIELD_VOYAGE_TO,
    // Constant.RXFIELD_VOYAGE,
    // Constant.RXFIELD_VOYAGE_PO_TRANSIT,
    // Constant.RXFIELD_VOYAGE_PO_DESTINATION,
    // Constant.RXFIELD_VOYAGE_SHIPPING_LINE,
    // Constant.RXFIELD_FORWARDER_ID,
    Constant.RXFIELD_PAYMENT_ON_BEHALF_OF,
    // Constant.RXFIELD_BRUTO,
    // Constant.RXFIELD_CONTAINER_VOLUME,
    // Constant.RXFIELD_CONTAINER_TYPE,
    // Constant.RXFIELD_CONTAINER_AMOUNT,
    // Constant.RXFIELD_CONTAINER_BRAND,
    // Constant.RXFIELD_CONTAINER_HSC,
    // Constant.RXFIELD_CONTAINER_DESC,
    // Constant.RXFIELD_CONTAINER_CONSIGNEE,
    // Constant.RXFIELD_CONTAINER_CONSIGNEE_ADDR,
    // Constant.RXFIELD_DG_CLASS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateBookExport = (bookExport) => {
  const error = rxformValidateBookExport(bookExport);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const rxformValidateAssignTruck = (values) => {
  const requiredFields = [
    Constant.RXFIELD_CONTAINER_NO,
    Constant.RXFIELD_BL_NO,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateAssignTruck = (assignTruck) => {
  const error = rxformValidateAssignTruck(assignTruck);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }

  if (!assignTruck.truckNo && !assignTruck.vendorId) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }
};

export const rxformValidateAssignClearance = (values, isExportService) => {
  const requiredFields = [
    Constant.RXFIELD_BL_NO,
    Constant.RXFIELD_FORWARDER_ID,
  ];

  if (!isExportService) {
    requiredFields.push(Constant.RXFIELD_SO_NO);
  }

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateAssignClearance = (assignCl, isExportService) => {
  const error = rxformValidateAssignClearance(assignCl, isExportService);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }

  if (assignCl.isTrucker && !assignCl.truckerId) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }
};

export const rxformValidateNewUser = (values) => {
  const requiredFields = [
    Constant.RXFIELD_MASTER_USER_USERNAME,
    Constant.RXFIELD_MASTER_USER_EMAIL,
    Constant.RXFIELD_MASTER_USER_FULLNAME,
    Constant.RXFIELD_MASTER_USER_COMPANY_ID,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateNewUser = (userInfo) => {
  const error = rxformValidateNewUser(userInfo);

  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (!validEmail(userInfo.email)) {
    throw new Error(LocalizedString.common.errMsgInvalidEmailFormat);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const rxformValidateNewForm = (values) => {
  const requiredFields = [
    Constant.RXFIELD_FORM_TITLE,
    Constant.RXFIELD_FORM_STATUS,
    Constant.RXFIELD_FORM_DESCRIPTION,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateNewForm = (userInfo) => {
  const error = rxformValidateNewForm(userInfo);

  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const rxformValidateNewField = (values) => {
  const requiredFields = [
    Constant.RXFIELD_FIELD_FORM,
    Constant.RXFIELD_FIELD_NAME,
    Constant.RXFIELD_FIELD_LABEL,
    Constant.RXFIELD_FIELD_REQUIRED,
    Constant.RXFIELD_FIELD_TYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateNewField = (userInfo) => {
  const error = rxformValidateNewField(userInfo);

  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const rxformValidateAssignContainerAdd = (values) => {
  const requiredFields = [
    Constant.RXFIELD_ASSIGN_CONTAINER_NO,
    Constant.RXFIELD_ASSIGN_BL_NO,
    Constant.RXFIELD_ASSIGN_SIZE,
    Constant.RXFIELD_ASSIGN_TYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateAssignContainerAdd = (assignContainerAdd) => {
  const error = rxformValidateAssignContainerAdd(assignContainerAdd);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }

  if (!assignContainerAdd.truckNo && !assignContainerAdd.vendorId) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }
};

export const rxformValidateRegisterPassword = (values) => {
  const requiredFields = [
    Constant.RXFIELD_PASSWORD,
    Constant.RXFIELD_REPASSWORD,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateRegisterPassword = (passwords) => {
  const error = rxformValidateRegisterPassword(passwords);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }

  if (passwords.password !== passwords.repassword) {
    throw new Error(LocalizedString.common.errMsgPasswordDoesNotMatch);
  }
};

export const rxformValidatePin = (values) => {
  const requiredFields = [
    Constant.RXFIELD_PIN,
    Constant.RXFIELD_REPIN,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateRegisterPin = (pins) => {
  const error = rxformValidatePin(pins);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }

  if (pins.pin !== pins.repin) {
    throw new Error(LocalizedString.common.errMsgPinDoesNotMatch);
  }

  validatePin(pins.pin);
};

export const rxformValidateChangePin = (values) => {
  const requiredFields = [
    Constant.RXFIELD_OLD_PIN,
    Constant.RXFIELD_NEW_PIN,
    Constant.RXFIELD_RE_NEW_PIN,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (values.newPassword !== values.reNewPassword) {
    errors.reNewPassword = LocalizedString.common.errMsgPinDoesNotMatch;
  }

  return errors;
};

export const rxformValidateBookExportParty = (values) => {
  const partiesWithoutDeleted = values.filter(item => !item.deleted);
  if (partiesWithoutDeleted.length === 0) {
    throw new Error(LocalizedString.bookExport.errorEmptyParty);
  }
};


export const rxformValidateAdminPayment = (values) => {
  const requiredFields = [
    Constant.RXFIELD_ADMIN_PAYMENT_INVOICE_NO,
    Constant.RXFIELD_ADMIN_PAYMENT_PAYMENT_OWNER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const validateAdminPayment = (paymentData) => {
  const error = rxformValidateAdminPayment(paymentData);
  const values = _.values(error);

  if (values.some(x => x === LocalizedString.common.errMsgRequired)) {
    throw new Error(LocalizedString.common.errMsgEmptyRequiredFields);
  }

  if (values.length > 0) {
    throw new Error(values[0]);
  }
};

export const rxformValidateImportRelease = (values) => {
  const requiredFields = [
    Constant.RXFIELD_IMPORT_RELEASE_BL_NO,
    Constant.RXFIELD_IMPORT_RELEASE_BL_TYPE,
    Constant.RXFIELD_IMPORT_RELEASE_MASTER_BL_NUMBER,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};

export const rxformValidateNewDocumentMyForm = (values, props) => {
  let requiredFields = [];
  if (props.myForm && props.myForm.fields) {
    requiredFields = props.myForm.fields.reduce((newArr, field) => {
      if (field.required === true) {
        newArr.push(field.name);
      }
      return newArr;
    }, []);
  }
  const errors = rxFormValidateRequiredFields(
    values,
    [...requiredFields, Constant.RXFIELD_NEW_DOCUMENT_PAYMENT_ON_BEHALF],
  );
  return errors;
};

export const rxformValidateTruckingBaseDialog = (values) => {
  const requiredFields = [
    Constant.RXFIELD_TRUCKING_BASE_DIALOG_NOTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);

  if (!values) {
    errors.values = LocalizedString.common.errMsgEmptyRequiredFields;
  }
  return errors;
};
