export const REST_BASE_URL = 'https://apps.cikarangdryport.com:8443/api/v1';
export const REST_URL_ASSIGN = '/assign';
export const REST_URL_ASSIGN_ORDER_TYPE = '/assign/order-type?bl_no={blNo}';
export const REST_URL_ASSIGN_CONTAINER = '/assign/container?bl_no={blNo}&so_no={soNo}';
export const REST_URL_ASSIGN_ADD_CONTAINER = '/assign/add-container';
export const REST_URL_ASSIGN_TRUCK_EXPORT = '/assign/truck-export';
export const REST_URL_CONTACT_US = '/contact-us';
export const REST_URL_FORGET_PASSWORD = '/password/forget';
export const REST_URL_REGISTER_PASSWORD = '/password/init';
export const REST_URL_REGISTER = '/account/register';
export const REST_URL_ACTIVATE_REGISTRATION = '/account/register/activate';
export const REST_URL_RESET_PASSWORD = '/password/reset';
export const REST_URL_CHANGE_PASSWORD = '/password/change';
export const REST_URL_CHANGE_PIN = '/pin/change';
export const REST_URL_LOGIN = '/login';
export const REST_URL_LOGOUT = '/logout';
export const REST_URL_MYPROFILE = '/myprofile';
export const REST_URL_DEPOSIT_REFRESH = '/deposit/refresh';
export const REST_URL_PROFILE_PICTURE = '/profile/{id}/picture';
export const REST_URL_PROFILE = '/profile';
export const REST_URL_UPDATE_PROFILE = '/profile/{id}';
export const REST_URL_FORWARDER = '/feeder-service/forwarder';
export const REST_URL_VENDOR = '/customer?name={name}';
export const REST_URL_CONTAINER_TRACKING = '/container-tracking';
export const REST_URL_CONTAINER_TRACKING_DETAIL = '/container-tracking-detail?bl_no={blNo}';
export const REST_URL_EXPORT = '/export';
export const REST_URL_EXPORT_ORDER_HISTORY_DETAIL = '/export-detail?co_ref={coRef}';
export const REST_URL_FEEDER_SERVICE_DISCLAIMER = '/feeder-service/disclaimer';
export const REST_URL_FEEDER_SERVICE_ORDER_HISTORY = '/feeder-service';
export const REST_URL_FEEDER_SERVICE_ORDER_DETAIL = '/feeder-service-detail?bl_no={blNo}';
export const REST_URL_FEEDER_SERVICE_FORWARDER_LIST = '/feeder-service/forwarder';
export const REST_URL_FEEDER_SERVICE_EMPTY_DEPO_LIST = '/feeder-service/empty-depo';
export const REST_URL_FEEDER_SERVICE_AREA_LIST = '/feeder-service/area?exim={exim}';
export const REST_URL_FEEDER_SERVICE_WAREHOUSE_LIST = '/feeder-service/warehouse?billto={key}';
export const REST_URL_FEEDER_SERVICE_BOOK_PARTY_LIST = '/feeder-service/book-party?book_no={bookNo}';
export const REST_URL_FEEDER_SERVICE_BL_LIST = '/feeder-service/blno';
export const REST_URL_MY_PAYMENT_TERM = '/my-term-order';
export const REST_URL_MY_TERM_ORDER = '/term-order';
export const REST_URL_REEFER_DATA = '/reefer-data';
export const REST_URL_REEFER_DATA_DETAIL = '/reefer-data-detail?bl_no={blNo}&container_no={containerNo}';
export const REST_URL_SHOPPING_CART = '/shopping-cart';
export const REST_URL_TARIFF_SIMULATION = '/tariff?bl_no={blNo}&etd={etd}&pob={pob}';
export const REST_URL_PPJK_COMPANY_LIST = '/ppjk-by-customer?cust_id={custId}';
export const REST_URL_CUSTOMER_COMPANY_LIST = '/customer-by-ppjk?ppjk_id={ppjkId}';
export const REST_URL_BOOK_FEEDER = '/feeder-service';
export const REST_URL_BOOK_PARTY_DOCUMENT = '/feeder-service/book-party-document';
export const REST_URL_SUBMIT_ORDER = '/order';
export const REST_URL_VESSEL_BY_NAME = '/vessel?name={name}';
export const REST_URL_VOYAGE_BY_NAME = '/voyage?vessel={vessel}&name={name}';
export const REST_URL_VOYAGE_DETAIL = '/vessel-voyage?vessel={vesselName}&voyage={voyageName}';
export const REST_URL_TARIFF_SIMULATION_ORDER = '/tariff-simulation';
export const REST_URL_MY_OUTSTANDING_PAYMENT = '/myorder/outstanding';
export const REST_URL_MY_OUTSTANDING_TERM_PAYMENT = '/my-term-order/outstanding';
export const REST_URL_MY_PAYMENT_ORDER = '/myorder';
export const REST_URL_MY_PAYMENT_ORDER_CSV_REQ = '/myorder/csv-req';
export const REST_URL_PPJK_LIST_BY_ASSIGN = '/assign/ppjk?name={name}';
export const REST_URL_TRUCK_LIST = '/assign/truck?vendor_id={vendorId}&truck_no={truckNo}';
export const REST_URL_SUBMIT_ASSIGN_CONTAINER = '/assign/container';
export const REST_URL_SUBMIT_ASSIGN_CLEARANCE = '/assign/clearance';
export const REST_URL_MY_NOTIFICATION = '/mynotification';
export const REST_URL_PRINT_REQUEST = '/print-request';
export const REST_URL_DEPOSIT_HISTORY = '/deposit/my-history?start_date={startDate}&end_date={endDate}';
export const REST_URL_DEPOSIT_HISTORY_CSV_REQ = '/deposit/my-history/csv-req?start_date={startDate}&end_date={endDate}';
export const REST_URL_DO_LIST = '/export/do_no';
export const REST_URL_DG_CLASS_LIST = '/export/dg-class';
export const REST_URL_ADMIN_PAYMENT_HISTORY = '/order/all?page={pageNumber}&size={pageSize}&sort={sortField}&sort_order={sortDirection}&search_text={searchText}';
export const REST_URL_ADMIN_PAYMENT_HISTORY_CSV_REQ = '/order/all/csv-req';
// export const REST_URL_ADMIN_PAYABLE_BL = '/order/all?key={key}';
export const REST_URL_ADMIN_PAYABLE_BL = '/other-bl?refno={key}';
export const REST_URL_ADMIN_PAYABLE_BL_PAYMENT_OWNER = '/payment-owner?companyid={key}';
export const REST_URL_CREATE_ADMIN_PAYMENT = '/order-other';
export const REST_URL_SERVICE_PRICE_DETAIL = '/service/detail-price?bl_no={blNo}&service={service}&pob={pob}&etd={etd}';
export const REST_URL_CANCEL_ORDER = '/order/cancel-order';
export const REST_URL_CHANGE_ORDER_PAYMENT = '/order/change-payment';
export const REST_URL_UNASSIGNED_TARIFF_SIMULATION_ORDER = '/tariff-simulation-unassigned?blno={blNo}';
export const REST_URL_TRACKING_STATUS_BEHANDLE_HISTORIES = '/behandle-tracking-history';
export const REST_URL_TRACKING_STATUS_BEHANDLE_ON_PROGRESSES = '/behandle-tracking-on-process';
export const REST_URL_PREDEFINED_ORDER = '/predefinedorder';
export const REST_URL_MONEY_LOAN_INFO = '/moneyloan/info';
export const REST_URL_MONEY_LOAN_HISTORY = '/moneyloan/myhistory';
export const REST_URL_MONEY_LOAN_TERMS_CONDITIONS = '/moneyloan/tnc';
export const REST_URL_MONEY_LOAN_SUBMIT_LOAN = '/moneyloan';
export const REST_URL_MONEY_LOAN_HISTORY_REQUEST_DOWNLOAD_CSV = '/moneyloan/myhistory/csv-req';
export const REST_URL_MONEY_LOAN_HISTORY_DOWNLOAD_CSV = `${REST_BASE_URL}/moneyloan/myhistory/csv/{printId}`;
export const REST_URL_TERM_AND_CONDITION = '/payment/tnc';
export const REST_URL_TARIFF_SIMULATION_HOST_BL = '/tariff-simulation-host-bl?blno={blNo}';
export const REST_URL_TARIFF_SIMULATION_SUBMIT_HOST_BL = '/tariff-simulation-host-bl';
export const REST_URL_DOC_SUBMISSION_MY_RESPONSES = '/doc-submission/myresponse';
export const REST_URL_DOC_SUBMISSION_MY_RESPONSE_DETAIL = '/doc-submission/myresponse/{id}';
export const REST_URL_DOC_SUBMISSION_MY_FORMS = '/doc-submission/myform';
export const REST_URL_DOC_SUBMISSION_MY_FORM_DETAIL = '/doc-submission/myform/{id}';
export const REST_URL_DOC_SUBMISSION_MY_FORM_ADD_RESPONSE = '/doc-submission/response';
export const REST_URL_DOC_SUBMISSION_MY_RESPONSE_DETAIL_FILE = '/doc-submission/response-media/{file}';
export const REST_URL_PAYMENT_ON_BEHALF = '/payment-on-behalf';
export const REST_URL_FORM_RESPONSES = '/doc-submission/response?&status={status}&page={pageNumber}&size={pageSize}';
export const REST_URL_FORM_RESPONSE_DETAIL = '/doc-submission/response/{id}';
export const REST_URL_FORM = '/doc-submission/form';
export const REST_URL_FORM_DETAIL = '/doc-submission/form/{id}';
export const REST_URL_FIELD = '/doc-submission/field';
export const REST_URL_FIELD_DETAIL = '/doc-submission/field/{id}';
export const REST_URL_FORM_RESPONSE_FILTER_STATUSES = '/doc-submission/response/status';
export const REST_URL_ERP_PAYMENT_LOGS = '/erp-payment/log?';
export const REST_URL_ERP_PAYMENT_LOG_DETAIL = '/erp-payment/log/{id}';
export const REST_URL_ERP_PAYMENT_LOG_RETRY = '/erp-payment/log-retry/{id}';
export const REST_URL_INVOICE_INFO = '/invoice-info?invoiceNo={invoiceNo}';

export const REST_METHOD_POST = 'POST';
export const REST_METHOD_GET = 'GET';
export const REST_METHOD_DELETE = 'DELETE';
export const REST_METHOD_PUT = 'PUT';

export const URL_REGISTRATION = 'http://register.cikarangdryport.com:8012/registermycdp/';
export const URL_LIVE_TRACKING = 'https://map.google.com?id={containerNo}';
export const URL_PAYMENT = `${REST_BASE_URL}/payment-init/{id}`;
export const URL_TOP_UP_GUIDE = `${REST_BASE_URL}/payment/va-how-to`;
export const URL_PRINT = `${REST_BASE_URL}/print/{id}`;
export const URL_DEPOSIT_HISTORY_CSV = `${REST_BASE_URL}/deposit/my-history/csv/{id}`;
export const URL_MY_PAYMENT_HISTORY_CSV = `${REST_BASE_URL}/myorder/csv/{id}`;
export const URL_ADMIN_PAYMENT_HISTORY_CSV = `${REST_BASE_URL}/order/all/csv/{id}?duration_days={duration}`;

export const IMAGE_SOURCE_URI_PREFIX = 'data:image/png;base64,';
export const IMAGE_BACKGROUND_LOGIN = '/assets/images/bg.jpg';
export const IMAGE_ICON_PHONE = '/assets/images/i_phone.png';
export const IMAGE_ICON_MESSAGE = '/assets/images/i_wa.png';
export const IMAGE_LOGO_CDP_2 = '/assets/images/logo_cdp_strong.png';

export const PASSWORD_MIN_LENGTH = 6;
export const PIN_LENGTH = 6;

export const KEY_REDUX_STATE = 'redux-state';

export const HTTP_HEADER_VALUE_JSON = 'application/json';

export const ROUTE_NAME_LOGIN = '/login';
export const ROUTE_NAME_LOGOUT = '/logout';
export const ROUTE_NAME_FORGET_PASSWORD = '/forget-password';
export const ROUTE_NAME_REGISTER_EMAIL = '/register';
export const ROUTE_NAME_REGISTER_PASSWORD = '/regpassword/:id';
export const ROUTE_NAME_PIN_VERIFICATION_FORGET_PASSWORD = '/forget-password/pin';
export const ROUTE_NAME_PIN_VERIFICATION_REGISTER_EMAIL = '/register/pin';
export const ROUTE_NAME_REGISTRATION_INFO = '/register/info';
export const ROUTE_NAME_RESET_PASSWORD = '/forget-password/reset';
export const ROUTE_NAME_SPLASH = '/splash';
export const ROUTE_NAME_HOME = '/home';
export const ROUTE_NAME_SETTING = '/setting';
export const ROUTE_NAME_PROFILE = '/profile';
export const ROUTE_NAME_PROFILE_CHANGE_PASSWORD = '/profile/change-password';
export const ROUTE_NAME_PROFILE_CHANGE_PIN = '/profile/change-pin';
export const ROUTE_NAME_NOTIFICATION = '/notification';
export const ROUTE_NAME_USER_MANAGEMENT = '/admin/users';
export const ROUTE_NAME_MASTER_FORWARDER = '/admin/forwarders';
export const ROUTE_NAME_MASTER_VENDORS = '/admin/vendors';
export const ROUTE_NAME_ADMIN_PAYMENT_HISTORY = '/admin/payment-history';
export const ROUTE_NAME_MASTER_TRUCKS = '/admin/trucks';
export const ROUTE_NAME_MASTER_USERS = '/master/users';
export const ROUTE_NAME_ORDER_DETAIL_CUSTOMER = '/order/detail-customer';
export const ROUTE_NAME_ORDER_DETAIL_VENDOR = '/order/detail-vendor';
export const ROUTE_NAME_ORDER_LIST_CUSTOMER = '/order/list';
export const ROUTE_NAME_ORDER_LIST_EXPORT_IN = '/order/export-in';
export const ROUTE_NAME_DASHBOARD_USER = '/dashboard';
export const ROUTE_NAME_FORM = '/form';
export const ROUTE_NAME_FIELD = '/field';
export const ROUTE_NAME_ABOUT_US = '/about-us';
export const ROUTE_NAME_CONTACT_US = '/contact-us';
export const ROUTE_NAME_CONTAINER_TRACKING = '/container-tracking';
export const ROUTE_NAME_CONTAINER_TRACKING_DETAIL = '/container-tracking/{id}';
export const ROUTE_NAME_TRUCKING = '/trucking';
export const ROUTE_NAME_REEFER_DATA = '/reefer-data';
export const ROUTE_NAME_TARIFF_SIMULATION = '/import';
export const ROUTE_NAME_TARIFF_SIMULATION_RELEASE = '/import-release';
export const ROUTE_NAME_TARIFF_SIMULATION_RELEASE_SEARCH = '/import-release-search';
export const ROUTE_NAME_CART_PAGE = '/cart';
export const ROUTE_NAME_PAYMENT_HISTORY = '/payment/history';
export const ROUTE_NAME_CHECKOUT_PAGE = '/checkout';
export const ROUTE_NAME_ASSIGN = '/assign';
export const ROUTE_NAME_EXPORT = '/export';
export const ROUTE_NAME_MY_PAYMENT = '/payment';
export const ROUTE_NAME_DOCUMENT_SUBMISSION = '/document-submission';
export const ROUTE_NAME_NEW_DOCUMENT = '/new-document';
export const ROUTE_NAME_DEPOSIT_HISTORY = '/deposit-history';
export const ROUTE_NAME_EXPORT_HISTORY = '/export-history';
export const ROUTE_NAME_TRUCKING_HISTORY = '/trucking-history';
export const ROUTE_NAME_CONTAINER_TRACKING_HISTORY = '/container-tracking-history';
export const ROUTE_NAME_USER_PAYMENT_HISTORY = '/my-payment-history';
export const ROUTE_NAME_TRACKING_STATUS_BEHANDLE = '/tracking-status-behandle';
export const ROUTE_NAME_RESPONSE = '/response';
export const ROUTE_NAME_ADMIN_ERP_PAYMENT_LOG = '/erp-payment-log';

export const RXFORM_REGISTER_PASSWORD = 'RXFORM_REGISTER_PASSWORD';
export const RXFORM_PROFILE_SCREEN_INFO = 'profileScreenInfo';
export const RXFORM_PROFILE_SCREEN_PASSWORD = 'profileScreenPassword';
export const RXFORM_PROFILE_SCREEN_PIN = 'profileScreenPin';
export const RXFORM_LOGIN_SCREEN = 'loginScreen';
export const RXFORM_RESET_PASSWORD_SCREEN = 'resetPasswordScreen';
export const RXFORM_REGISTRATION_INFO_SCREEN = 'registrationInfoScreen';
export const RXFORM_ADD_USER = 'addUser';
export const RXFORM_UPDATE_USER = 'updateUser';
export const RXFORM_CONTACT_US = 'contactUs';
export const RXFORM_MASTER_FORWARDER = 'mstForwarder';
export const RXFORM_MASTER_FORWARDER_ADD = 'addMstForwarder';
export const RXFORM_MASTER_TRUCK = 'mstTruck';
export const RXFORM_MASTER_TRUCK_ADD = 'addMstTruck';
export const RXFORM_MASTER_USER = 'mstUser';
export const RXFORM_MASTER_USER_ADD = 'addMstUser';
export const RXFORM_FORM = 'form';
export const RXFORM_FORM_ADD = 'addForm';
export const RXFORM_FORM_EDIT = 'editForm';
export const RXFORM_FIELD = 'field';
export const RXFORM_FIELD_ADD = 'addField';
export const RXFORM_FIELD_EDIT = 'editField';
export const RXFORM_FIELD_FILTER = 'filterField';
export const RXFORM_MASTER_VENDOR = 'mstVendor';
export const RXFORM_MASTER_VENDOR_ADD = 'addMstVendor';
export const RXFORM_BOOK_EXPORT_FEEDER = 'addExportBookingFeeder';
export const RXFORM_ASSIGN_CONTAINER_TRUCK = 'assignContainerTruck';
export const RXFORM_ASSIGN_CLEARANCE = 'assignClearance';
export const RXFORM_ADD_CONTAINER = 'addContainer';
export const RXFORM_ADD_BOOKING_TRUCK = 'addBooking';
export const RXFORM_ADD_ADMIN_PAYMENT = 'addAdminPayment';
export const RXFORM_IMPORT_RELEASE = 'importRelease';
export const RXFORM_NEW_DOCUMENT_MY_FORM = 'newDocumentMyForm';
export const RXFORM_DOC_SUBMISSION_MY_RESPONSE_DETAIL = 'docSubmissionMyResponseDetail';
export const RXFORM_FORM_RESPONSE_DETAIL = 'formResponseDetail';
export const RXFORM_RESPONSE_FILTER_DIALOG = 'responseFilterDialog';
export const RXFORM_ERP_PAYMENT_LOG_DETAIL = 'erpPaymentLogDetail';
export const RXFORM_ERP_PAYMENT_LOG_FILTER_DIALOG = 'erpPaymentLogFilterDialog';
export const RXFORM_TARIFF_SIMULATION_DIALOG = 'tariffSimulationDialog';
export const RXFORM_TRUCKING_BASE_DIALOG = 'truckingBaseDialog';

export const RXFIELD_REMEMBER_ME = 'rememberMe';
export const RXFIELD_EMAIL = 'email';
export const RXFIELD_FULLNAME = 'fullName';
export const RXFIELD_ROLE = 'role';
export const RXFIELD_USER_ROLE = 'userRole';
export const RXFIELD_COMPANY_NAME = 'companyName';
export const RXFIELD_COMPANY_ROLE = 'companyRole';
export const RXFIELD_ADDRESS = 'address';
export const RXFIELD_PHONE = 'phone';
export const RXFIELD_JOB_TITLE = 'jobTitle';
export const RXFIELD_USERNAME = 'username';
export const RXFIELD_PASSWORD = 'password';
export const RXFIELD_REPASSWORD = 'repassword';
export const RXFIELD_OLD_PASSWORD = 'oldPassword';
export const RXFIELD_NEW_PASSWORD = 'newPassword';
export const RXFIELD_RE_NEW_PASSWORD = 'reNewPassword';
export const RXFIELD_SUBJECT = 'subject';
export const RXFIELD_MESSAGE = 'message';
export const RXFIELD_PIN = 'pin';
export const RXFIELD_REPIN = 'repin';
export const RXFIELD_OLD_PIN = 'oldPin';
export const RXFIELD_NEW_PIN = 'newPin';
export const RXFIELD_RE_NEW_PIN = 'reNewPin';

export const RXFIELD_BL_NO = 'blNo';
export const RXFIELD_PAYMENT_ON_BEHALF_OF = 'paymentOnBehalfOf';
export const RXFIELD_BILL_TO = 'billTo';
export const RXFIELD_FORWARDER = 'forwarder';
export const RXFIELD_DO_NO = 'doNo';
export const RXFIELD_DO_DATE = 'doDate';
export const RXFIELD_DO_EXPIRED = 'doExpired';
export const RXFIELD_EMPTY_DEPO = 'emptyDepo';
export const RXFIELD_AREA = 'area';
export const RXFIELD_WAREHOUSE = 'warehouse';
export const RXFIELD_SPC_DATE = 'spcDate';
export const RXFIELD_PLAN_DATE = 'planDate';

export const RXFIELD_IS_TRUCKER = 'isTrucker';

export const RXFIELD_PEB_NO = 'pebNo';
export const RXFIELD_PEB_DATE = 'pebDate';
export const RXFIELD_VESSEL = 'vessel';
export const RXFIELD_VOYAGE = 'voyage';
export const RXFIELD_VOYAGE_TO = 'toid';
export const RXFIELD_VOYAGE_TO_CLOSING_DATETIME = 'toClosingDateTime';
export const RXFIELD_VOYAGE_TO_CLOSING_DATETIME_FORMATTED = 'toClosingDateTimeFormatted';
export const RXFIELD_VOYAGE_SHIPPING_LINE = 'shippingLineCode';
export const RXFIELD_VOYAGE_VESSEL_ID = 'vesselId';
export const RXFIELD_VOYAGE_VESSEL_OWNER = 'vesselOwner';
export const RXFIELD_VOYAGE_ETD = 'etd';
export const RXFIELD_VOYAGE_ETD_FORMATTED = 'etdFormatted';
export const RXFIELD_VOYAGE_ATA = 'ata';
export const RXFIELD_VOYAGE_ATA_FORMATTED = 'ataFormatted';
export const RXFIELD_VOYAGE_PO_DESTINATION = 'poDestination';
export const RXFIELD_VOYAGE_PO_TRANSIT = 'poTransit';
export const RXFIELD_BRUTO = 'gross';
export const RXFIELD_CONTAINER_VOLUME = 'containerVolume';
export const RXFIELD_CONTAINER_TYPE = 'containerType';
export const RXFIELD_CONTAINER_AMOUNT = 'containerAmount';
export const RXFIELD_CONTAINER_BRAND = 'containerBrand';
export const RXFIELD_CONTAINER_HSC = 'containerHsc';
export const RXFIELD_CONTAINER_DESC = 'containerDescription';
export const RXFIELD_CONTAINER_CONSIGNEE = 'containerConsignee';
export const RXFIELD_CONTAINER_CONSIGNEE_ADDR = 'containerConsigneeAddress';
export const RXFIELD_CONTAINER_NO = 'containerNo';
export const RXFIELD_VENDOR_ID = 'vendorId';
export const RXFIELD_TRUCK_NO = 'truckNo';
export const RXFIELD_SO_NO = 'soNo';
export const RXFIELD_FORWARDER_ID = 'forwarderId';
export const RXFIELD_TRUCKER_ID = 'truckerId';
export const RXFIELD_DG_CLASS = 'dgClass';
export const RXFIELD_DG_SUB_CLASS = 'dgSubClass';

export const RXFIELD_NUMBER_OF_PARTY = 'numberOfParty';
export const RXFIELD_SIZE_OF_PARTY = 'sizeOfParty';
export const RXFIELD_TYPE_OF_PARTY = 'typeOfParty';

export const RXFIELD_ASSIGN_BL_NO = 'blNo';
export const RXFIELD_ASSIGN_CONTAINER_NO = 'containerNo';
export const RXFIELD_ASSIGN_CONTAINER_SIZE = 'containerSize';
export const RXFIELD_ASSIGN_CONTAINER_TYPE = 'containerType';
export const RXFIELD_ASSIGN_VENDOR = 'vendorId';
export const RXFIELD_ASSIGN_TRUCK_NO = 'truckNo';
export const RXFIELD_ASSIGN_TYPE = 'ctrType';
export const RXFIELD_ASSIGN_SIZE = 'ctrSize';

export const RXFIELD_MASTER_FORWARDER_ID = 'id';
export const RXFIELD_MASTER_FORWARDER_NAME = 'name';
export const RXFIELD_MASTER_FORWARDER_ADDR1 = 'addr1';
export const RXFIELD_MASTER_FORWARDER_NPWP = 'npwp';
export const RXFIELD_MASTER_FORWARDER_CITY = 'city';
export const RXFIELD_MASTER_FORWARDER_PROVINCE = 'province';

export const RXFIELD_MASTER_TRUCK_ID = 'id';
export const RXFIELD_MASTER_TRUCK_NO = 'truckNo';
export const RXFIELD_MASTER_TRUCK_RFID = 'rfid';
export const RXFIELD_MASTER_TRUCK_LICENSE_PLATE_NO = 'licensePlateNumber';
export const RXFIELD_MASTER_TRUCK_VENDOR = 'vendorId';
export const RXFIELD_MASTER_TRUCK_CID = 'cid';
export const RXFIELD_MASTER_TRUCK_WEIGHT = 'weight';

export const RXFIELD_MASTER_USER_USERNAME = 'username';
export const RXFIELD_MASTER_USER_EMAIL = 'email';
export const RXFIELD_MASTER_USER_FULLNAME = 'fullName';
export const RXFIELD_MASTER_USER_IS_CORPORATE_ADMIN = 'corporateAdmin';
export const RXFIELD_MASTER_USER_IS_ADMIN = 'isAdmin';
export const RXFIELD_MASTER_USER_COMPANY_ID = 'companyId';
export const RXFIELD_MASTER_USER_ROLE = 'role';

export const RXFIELD_MASTER_VENDOR_ID = 'id';
export const RXFIELD_MASTER_VENDOR_NAME = 'name';

export const RXFIELD_FORM_ORDER = 'order';
export const RXFIELD_FORM_TITLE = 'title';
export const RXFIELD_FORM_DESCRIPTION = 'description';
export const RXFIELD_FORM_STATUS = 'status';

export const RXFIELD_FIELD_FORM = 'formName';
export const RXFIELD_FIELD_NAME = 'name';
export const RXFIELD_FIELD_LABEL = 'label';
export const RXFIELD_FIELD_ORDER = 'order';
export const RXFIELD_FIELD_REQUIRED = 'required';
export const RXFIELD_FIELD_TYPE = 'type';
export const RXFIELD_FIELD_STATUS = 'status';

export const RXFIELD_ADMIN_PAYMENT_BL_NO = 'blNo';
export const RXFIELD_ADMIN_PAYMENT_INVOICE_NO = 'invoiceNo';
export const RXFIELD_ADMIN_PAYMENT_SERVICE = 'service';
export const RXFIELD_ADMIN_PAYMENT_BILL_TO = 'billTo';
export const RXFIELD_ADMIN_PAYMENT_PAYMENT_OWNER = 'paymentOwner';

export const RXFIELD_IMPORT_RELEASE_BL_NO = 'blNo';
export const RXFIELD_IMPORT_RELEASE_BL_TYPE = 'blType';
export const RXFIELD_IMPORT_RELEASE_MASTER_BL_NUMBER = 'masterBl';

export const RXFIELD_NEW_DOCUMENT_PAYMENT_ON_BEHALF = 'paymentOnBehalf';
export const RXFIELD_DOC_SUBMISSION_DETAIL_SUBMIT_ID = 'submitId';
export const RXFIELD_DOC_SUBMISSION_DETAIL_SUBMIT_DATE = 'submitDate';
export const RXFIELD_DOC_SUBMISSION_DETAIL_STATUS = 'status';
export const RXFIELD_DOC_SUBMISSION_DETAIL_STATUS_NOTES = 'statusNotes';
export const RXFIELD_DOC_SUBMISSION_DETAIL_FORM = 'form';
export const RXFIELD_DOC_SUBMISSION_DETAIL_USER = 'user';
export const RXFIELD_FORM_RESPONSE_DETAIL_FORM_ID = 'formId';
export const RXFIELD_FORM_RESPONSE_DETAIL_FORM_NAME = 'formName';
export const RXFIELD_FORM_RESPONSE_DETAIL_USER_ID = 'userId';
export const RXFIELD_FORM_RESPONSE_DETAIL_USER_NAME = 'userName';
export const RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_ID = 'submitId';
export const RXFIELD_FORM_RESPONSE_DETAIL_SUBMIT_DATE = 'submitDate';
export const RXFIELD_FORM_RESPONSE_DETAIL_STATUS = 'status';
export const RXFIELD_FORM_RESPONSE_DETAIL_STATUS_NOTES = 'statusNotes';
export const RXFIELD_FORM_RESPONSE_DETAIL_USER = 'userId';
export const RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_ID = 'paymentOnBehalfId';
export const RXFIELD_FORM_RESPONSE_DETAIL_PAYMENT_ON_BEHALF_NAME = 'paymentOnBehalfName';

export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_PROFORMA_NO = 'proformaNo';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_COMPANY = 'company';
export const RXFIELD_ERP_PAYMENT_LOG_AMOUNT = 'amount';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS = 'status';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_STATUS_NOTES = 'statusNotes';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_CREATED_DATE = 'createdDate';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_RETRY = 'retry';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_LAST_RETRY_ON = 'lastRetryOn';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_PAYLOAD = 'payload';
export const RXFIELD_ERP_PAYMENT_LOG_DETAIL_RESPONSE = 'response';

export const RXFIELD_TARIFF_SIMULATION_DIALOG_NOTES = 'notes';

export const RXFIELD_TRUCKING_BASE_DIALOG_NOTES = 'notes';

export const RXSTATE_CONTACT_US_PAGE = 'uiContactUs';
export const RXSTATE_LOGIN_PAGE = 'uiLogin';
export const RXSTATE_PROFILE_PAGE = 'uiProfile';
export const RXSTATE_FORGET_PASSWORD_PAGE = 'uiForgetPassword';
export const RXSTATE_REGISTRATION_PAGE = 'uiRegistration';
export const RXSTATE_USER_MANAGEMENT = 'uiUserManagement';
export const RXSTATE_MASTER_FORWARDER = 'uiMasterForwarder';
export const RXSTATE_MASTER_TRUCKING = 'uiMasterTrucking';
export const RXSTATE_MASTER_VENDOR = 'uiMasterVendor';
export const RXSTATE_WEB_USER_MAIN = 'uiWebUserMain';
export const RXSTATE_CONTAINER_TRACKING = 'uiContainerTracking';
export const RXSTATE_CONTAINER_TRACKING_DIALOG = 'uiContainerTrackingDialog';
export const RXSTATE_REEFER_DATA = 'uiReeferData';
export const RXSTATE_REEFER_DATA_DETAIL = 'uiReeferDataDetail';
export const RXSTATE_TARIFF_SIMULATION = 'uiTariffSimulation';
export const RXSTATE_FEEDER_SERVICE = 'uiFeederService';
export const RXSTATE_MY_PAYMENT = 'uiMyPayment';
export const RXSTATE_MY_PAYMENT_TERM = 'uiMyPaymentTerm';
export const RXSTATE_MY_PAYMENT_OUTSTANDING = 'uiMyPaymentOutstanding';
export const RXSTATE_CART_PAGE = 'uiCart';
export const RXSTATE_CHECKOUT_PAGE = 'uiCheckout';
export const RXSTATE_EXPORT_PAGE = 'uiExport';
export const RXSTATE_BOOK_EXPORT_PAGE = 'uiBookExport';
export const RXSTATE_ASSIGN_CONTAINER_TRUCK = 'uiAssignTruck';
export const RXSTATE_ASSIGN_CLEARANCE = 'uiAssignClearance';
export const RXSTATE_ASSIGN = 'uiAssign';
export const RXSTATE_REGISTER_PASSWORD = 'uiRegisterPassword';
export const RXSTATE_SEAL_NUMBER = 'sealNo';
export const RXSTATE_DOC_SUBMISSION_MY_RESPONSE = 'uiDocSubmissionMyResponse';
export const RXSTATE_NEW_DOCUMENT_MY_FORM = 'uiNewDocumentMyForm';

export const RXTABLEFIELD_ACTIVE_PAGE = 'activePage';
export const RXTABLEFIELD_PAGE_SIZE = 'pageSize';

export const TABLE_SORT_ASCENDING = 'asc';
export const TABLE_SORT_DESCENDING = 'desc';
export const TABLE_FIELD_BL_NO = 'blNo';
export const TABLE_FIELD_CONTAINER_NUMBER = 'containerNo';
export const TABLE_FIELD_GATE_IN_CDP = 'gateInCdp';
export const TABLE_FIELD_PARTY = 'party';
export const TABLE_FIELD_VENDOR = 'vendor';
export const TABLE_FIELD_SERVICE = 'exim';
export const TABLE_FIELD_SIZE = 'ctrSize';
export const TABLE_FIELD_SHIFT_DATE = 'shiftDate';
export const TABLE_FIELD_SHIFT_ID = 'shiftId';
export const TABLE_FIELD_SETTING_TEMPERATURE = 'settingPointTemp';
export const TABLE_FIELD_AIR_SUPPLY = 'supplyAirTemp';
export const TABLE_FIELD_SEAL_NUMBER = 'sealNo';
export const TABLE_FIELD_SO_NUMBER = 'soNo';
export const TABLE_FIELD_STATUS_HAULAGE = 'statusHaulage';
export const TABLE_FIELD_KMT_NO = 'kmtNo';
export const TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION = 'description';
export const TABLE_FIELD_TARIFF_SIMULATION_DAYS = 'days';
export const TABLE_FIELD_TARIFF_SIMULATION_PRICE = 'price';
export const TABLE_FIELD_TARIFF_SIMULATION_TOTAL = 'total';
export const TABLE_FIELD_ORDER_TYPE = 'orderType';
export const TABLE_FIELD_STATUS = 'status';
export const TABLE_FIELD_CONSIGNEE = 'billTo';
export const TABLE_FIELD_TRUCKER = 'forwarderId';

// payment history
export const TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER = 'blNumber';
export const TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE = 'service';
export const TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER = 'invoiceNo';
export const TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO = 'paymentOnBehalfOf';
export const TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT = 'amount';
export const TABLE_FIELD_MY_PAYMENT_HISTORY_NOTES = 'notes';

// common master
export const TABLE_FIELD_CREATED_USER = 'createdUser';
export const TABLE_FIELD_CREATED_DATE = 'createdDate';

// master table field
// vendor
export const TABLE_FIELD_MASTER_VENDOR_ID = 'index';
export const TABLE_FIELD_MASTER_VENDOR_VENDOR_ID = 'id';
export const TABLE_FIELD_MASTER_VENDOR_VENDOR_NAME = 'name';
// truck
export const TABLE_FIELD_MASTER_TRUCK_TRUCK_NO = 'truckNo';
export const TABLE_FIELD_MASTER_TRUCK_RFID = 'rfid';
export const TABLE_FIELD_MASTER_TRUCK_PLATE_NUMBER = 'licensePlateNumber';
export const TABLE_FIELD_MASTER_TRUCK_VENDOR = 'vendorId';
export const TABLE_FIELD_MASTER_TRUCK_CID = 'cid';
export const TABLE_FIELD_MASTER_TRUCK_WEIGHT = 'weight';
// truck
export const TABLE_FIELD_MASTER_FORWARDER_ID = 'id';
export const TABLE_FIELD_MASTER_FORWARDER_NAME = 'name';
export const TABLE_FIELD_MASTER_FORWARDER_ADDR = 'addr1';
export const TABLE_FIELD_MASTER_FORWARDER_NPWP = 'npwp';
export const TABLE_FIELD_MASTER_FORWARDER_CITY = 'city';
export const TABLE_FIELD_MASTER_FORWARDER_PROVINCE = 'province';
// order detail
export const TABLE_FIELD_ORDER_DETAIL_ID = 'id';
export const TABLE_FIELD_ORDER_DETAIL_CONTAINER_NO = 'containerNo';
export const TABLE_FIELD_ORDER_DETAIL_SO_NO = 'soNo';
export const TABLE_FIELD_ORDER_DETAIL_BL_NO = 'blNo';
export const TABLE_FIELD_ORDER_DETAIL_DRIVER_NAME = 'driverName';
export const TABLE_FIELD_ORDER_DETAIL_CONTAINER_NO_OUT = 'containerNoOut';
export const TABLE_FIELD_ORDER_DETAIL_SO_NO_OUT = 'soNoOut';
export const TABLE_FIELD_ORDER_DETAIL_BL_NO_OUT = 'blNoOut';
export const TABLE_FIELD_ORDER_DETAIL_GATE_IN_CDP = 'gateInCdp';
export const TABLE_FIELD_ORDER_DETAIL_GATE_OUT_CDP = 'gateOutCdp';
export const TABLE_FIELD_ORDER_DETAIL_TRUCK_NO = 'truckNo';
export const TABLE_FIELD_ORDER_DETAIL_STATUS = 'status';
// order list
export const TABLE_FIELD_ORDER_LIST_BILL_TO = 'billTo';
export const TABLE_FIELD_ORDER_LIST_BL_NO = 'blNo';
// master user
export const TABLE_FIELD_MASTER_USER_INDEX = 'index';
export const TABLE_FIELD_MASTER_USER_USERNAME = 'username';
export const TABLE_FIELD_MASTER_USER_EMAIL = 'email';
export const TABLE_FIELD_MASTER_USER_FULLNAME = 'fullName';
export const TABLE_FIELD_MASTER_USER_COMPANY_NAME = 'name';
export const TABLE_FIELD_MASTER_USER_REGISTERED_ON = 'registeredOn';

// form
export const TABLE_FIELD_FORM_TITLE = 'title';
export const TABLE_FIELD_FORM_ORDER = 'order';
export const TABLE_FIELD_FORM_STATUS = 'status';

// field
export const TABLE_FIELD_FIELD_TITLE = 'title';
export const TABLE_FIELD_FIELD_ORDER = 'order';
export const TABLE_FIELD_FIELD_STATUS = 'status';
export const TABLE_FIELD_FIELD_NAME = 'name';

// Admin Order
export const ADMIN_TAB_ORDER_ASSIGN_TRUCK = 0;
export const ADMIN_TAB_ORDER_ASSIGNED = 1;
export const ADMIN_TAB_ORDER_DONE = 2;

// deposit history
export const TABLE_FIELD_DEPOSIT_HISTORY_TYPE = 'type';
export const TABLE_FIELD_DEPOSIT_HISTORY_DATE = 'date';
export const TABLE_FIELD_DEPOSIT_HISTORY_AMOUNT = 'amount';
export const TABLE_FIELD_DEPOSIT_HISTORY_INVOICE_NO = 'referenceNo';
export const TABLE_FIELD_START_DATE = 'startDate';
export const TABLE_FIELD_END_DATE = 'endDate';
// money loan history
export const TABLE_FIELD_LOAN_HISTORY_AMOUNT = 'amount';
export const TABLE_FIELD_LOAN_HISTORY_STATUS = 'status';
export const TABLE_FIELD_LOAN_HISTORY_TERM_DAYS = 'termDays';
export const TABLE_FIELD_LOAN_HISTORY_CREATED_DATE = 'createdDate';
// Admin Payment History
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PROFORMA_NO = 'proformaNo';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_STATUS = 'paymentStatus';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_METHOD = 'paymentMethod';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_ON = 'paidOn';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE = 'paymentCode';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAYMENT_CODE_VALID_UNTIL = 'paymentCodeValidUntil';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CHANNEL = 'paymentChannel';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_CREATED_BY = 'createdByUser';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_PAID_BY = 'createdByCompany';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_BL_NO = 'blNumber';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NO = 'invoiceNo';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_AMOUNT = 'amount';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_SERVICE = 'service';
export const TABLE_FIELD_ADMIN_PAYMENT_HISTORY_DETAIL_INVOICE_NAME = 'paymentOnBehalfOf';
// Admin ERP Payment Log
export const TABLE_FIELD_ERP_PAYMENT_LOG_PROFORMA_NO = 'proformaNo';
export const TABLE_FIELD_ERP_PAYMENT_LOG_COMPANY = 'companyId';
export const TABLE_FIELD_ERP_PAYMENT_LOG_AMOUNT = 'amount';
export const TABLE_FIELD_ERP_PAYMENT_LOG_STATUS = 'status';
export const TABLE_FIELD_ERP_PAYMENT_LOG_CREATED_DATE = 'createdDate';
export const TABLE_FIELD_ERP_PAYMENT_ACTIONS = 'actions';

export const SERVICE_TYPE_PREDEFINED_ORDER = 'PredefinedOrder';
export const SERVICE_TYPE_TRUCKING = 'Trucking';

export const CART_SERVICE_NAME_EXPORT = 'Export';
export const CART_SERVICE_NAME_IMPORT = 'Import';
export const CART_SERVICE_NAME_TRUCKING = 'Trucking';

export const ROLE_BILLING = 'BILLING';
export const ROLE_ADMIN = 'ADMIN';
export const ROLE_PPJK = 'PPJK';
export const ROLE_CUSTOMER = 'CUSTOMER';
export const ROLE_CS = 'CS';

export const MENU_ID_DASHBOARD = 'MENU_ID_DASHBOARD';
export const MENU_ID_ADMINISTRATION = 'MENU_ID_ADMINISTRATION';
export const MENU_ID_USER_MANAGEMENT = 'MENU_ID_USER_MANAGEMENT';
export const MENU_ID_FORM = 'MENU_ID_FORM';
export const MENU_ID_FIELD = 'MENU_ID_FIELD';
export const MENU_ID_ADMIN_PAYMENT_HISTORY = 'MENU_ID_ADMIN_PAYMENT_HISTORY';
export const MENU_ID_MASTER_FORWARDER = 'MENU_ID_MASTER_FORWARDER';
export const MENU_ID_MASTER_TRUCK = 'MENU_ID_MASTER_TRUCK';
export const MENU_ID_MASTER_VENDOR = 'MENU_ID_MASTER_VENDOR';
export const MENU_ID_ORDER_DETAIL_CUSTOMER = 'MENU_ID_ORDER_DETAIL_CUSTOMER';
export const MENU_ID_ORDER_DETAIL_VENDOR = 'MENU_ID_ORDER_DETAIL_VENDOR';
export const MENU_ID_ORDER_LIST_CUSTOMER = 'MENU_ID_ORDER_LIST_CUSTOMER';
export const MENU_ID_ORDER_LIST_EXPORT_IN = 'MENU_ID_ORDER_LIST_EXPORT_IN';
export const MENU_ID_ORDER_LIST_FLEET = 'MENU_ID_ORDER_LIST_FLEET';
export const MENU_ID_HISTORY_IMPORT = 'MENU_ID_HISTORY_IMPORT';
export const MENU_ID_HISTORY_EXPORT = 'MENU_ID_HISTORY_EXPORT';
export const MENU_ID_HISTORY_TRUCKING = 'MENU_ID_HISTORY_TRUCKING';
export const MENU_ID_HISTORY_CONTAINER_TRACKING = 'MENU_ID_HISTORY_CONTAINER_TRACKING';
export const MENU_ID_HISTORY_PAYMENT = 'MENU_ID_HISTORY_PAYMENT';
export const MENU_ID_RESPONSE = 'MENU_ID_RESPONSE';
export const MENU_ID_ADMIN_ERP_PAYMENT_LOG = 'MENU_ID_ADMIN_ERP_PAYMENT_LOG';

export const MOMENT_DATE_FORMAT_STRING = 'DD MMM YYYY';
export const MOMENT_DATE_LONG_FORMAT_STRING = 'DD MMMM YYYY';
export const MOMENT_DATE_TIME_FORMAT_STRING = 'DD MMM YYYY HH:mm';
export const MOMENT_DATE_TIME_SEPARATED_FORMAT_STRING = 'DD MMM YYYY | HH:mm';
export const MOMENT_DATE_API_FORMAT_STRING = 'YYYY-MM-DD';

export const DRAWER_WIDTH = 256;
export const DRAWER_NOTIFICATION_WIDTH = 292;

export const MAX_NOTIFICATION_COUNT = 99;
export const DEFAULT_TEXTAREA_ROWS = 5;
export const DEFAULT_PAGE_SIZE_OPTIONS = [20, 50, 100];
export const DEFAULT_DOWNLOAD_LIMIT_DAYS_OPTIONS = [1, 2, 3, 4, 5, 6, 7];

export const DEFAULT_STYLES = {
  WEB_USER: {
    BACK_ICON: {
      backgroundColor: '#ffffff',
    },
    BUTTON_PRIMARY: {
      backgroundColor: '#50abf1',
    },
  },
};

export const MAP_URL_CONTACT_US = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1982.9860635651844!2d107.1642262!3d-6.2673961!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6984c5dec4c96b%3A0x3af37952046ba503!2sCikarang+Dry+Port!5e0!3m2!1sid!2sae!4v1552529856171';

export const OBJ_KEY_CONTAINER_TRACKING = 'blNo';
export const OBJ_KEY_CONTAINER_TRACKING_DETAIL = 'containerNo';
export const OBJ_KEY_REEFER_DATA = 'containerNo';
export const OBJ_KEY_REEFER_DATA_DETAILS = 'shiftId';
export const OBJ_KEY_FEEDER_SERVICE_ORDER_DETAILS = 'containerNo';
export const OBJ_KEY_FEEDER_SERVICE_ORDER_HISTORIES = 'refNo';
export const OBJ_KEY_CUSTOMER_COMPANY_LIST = 'id';
export const OBJ_KEY_PPJK_COMPANY_LIST = 'id';
export const OBJ_KEY_EXPORT = 'blNo';
export const OBJ_KEY_EXPORT_DETAIL = 'containerNo';
export const OBJ_KEY_FEEDER_SERVICE_FORWARDER_LIST = 'id';
export const OBJ_KEY_FEEDER_SERVICE_EMPTY_DEPO_LIST = 'mtId';
export const OBJ_KEY_FEEDER_SERVICE_AREA_LIST = 'code';
export const OBJ_KEY_FEEDER_SERVICE_WAREHOUSE_LIST = 'warehouseId';
export const OBJ_KEY_FEEDER_SERVICE_BOOK_PARTY_LIST = 'id';
export const OBJ_KEY_MY_PAYMENT_ORDER = 'proformaNo';
export const OBJ_KEY_MY_PAYMENT_TERM = 'id';
export const OBJ_KEY_TARIFF_SIMULATION_ORDER = 'blNo';
export const OBJ_KEY_MY_OUTSTANDING_PAYMENT = 'proformaNo';
export const OBJ_KEY_MY_OUTSTANDING_TERM_PAYMENT = 'invoiceNo';
export const OBJ_KEY_ASSIGN = 'blNo';
export const OBJ_KEY_ASSIGN_ORDER_TYPE = 'soNo';
export const OBJ_KEY_ASSIGN_CONTAINER = 'containerNo';
export const OBJ_KEY_PPJK_LIST_BY_ASSIGN = 'id';
export const OBJ_KEY_TRUCK_LIST = 'truckNo';
export const OBJ_KEY_NOTIFICATION_LIST = 'id';
export const OBJ_KEY_VENDOR = 'id';
export const OBJ_KEY_DEPOSIT_HISTORY = 'id';
export const OBJ_KEY_DO_LIST = 'doNo';
export const OBJ_KEY_EXPORT_DG_CLASS = 'id';
export const OBJ_KEY_ADMIN_PAYMENT_HISTORY = 'proformaNo';
export const OBJ_KEY_ADMIN_PAYABLE_BL = 'blNo';
export const OBJ_KEY_ADMIN_PAYABLE_BL_PAYMENT_OWNER = 'id';
export const OBJ_KEY_TRACKING_STATUS_BEHANDLE = 'containerNo';
export const OBJ_KEY_DOC_SUBMISISON_MY_RESPONSE = 'id';
export const OBJ_KEY_FORM = 'id';
export const OBJ_KEY_FIELD = 'id';
export const OBJ_KEY_ERP_PAYMENT_LOG = 'id';

export const DEFAULT_COMPANY = 'GTC';

export const PAYMENT_TYPE_DEPOSIT = 'DEPOSIT';
export const PAYMENT_TYPE_DIRECT = 'DIRECT';
export const PAYMENT_TYPE_CASH = 'CASH';
export const PAYMENT_TYPE_TERM = 'TERM';

export const PAYMENT_STATUS_SUCCESS = 'SUCCESS';
export const PAYMENT_STATUS_FAILED = 'FAILED';
export const PAYMENT_STATUS_PENDING = 'PENDING';

export const PARTY_SIZE = ['20', '40', '45'];
export const PARTY_TYPE = ['DRY', 'RFR', 'FLT'];
export const PARTY_LOAD_FCL = 'FCL';

export const DOC_NOT_RELEASED_STATUS = 'Doc Not Released';

export const FLAG_REEFER_CONTAINER = 'RFR';

export const BOOK_TRUCKING_STATUS_CONFIRMED = 'Confirmed';
export const BOOK_TRUCKING_STATUS_BOOKED = 'Booked';
export const BOOK_TRUCKING_STATUS_REJECTED = 'Rejected';

export const COLOR_ALMOST_DUE_CONTAINER = '#FFE8A4';
export const COLOR_PAST_DUE_CONTAINER = '#FFA4A4';
export const COLOR_ALMOST_DUE_CIRCLE = '#FFBF00';
export const COLOR_PAST_DUE_CIRCLE = '#D91F1F';
export const COLOR_ACCENT = '#5CB860';
export const COLOR_PRIMARY = '#0066CB';
export const COLOR_BACKGROUND = '#FFFFFF';
export const COLOR_BACKGROUND_SECONDARY = '#E8F2FF';
export const COLOR_SECONDARY = '#72BF44';
export const COLOR_TERTIARY = '#C2BD41';

export const COLOR_STATUS_WAITING_LIST = 'rgb(255,253,84)';
export const COLOR_STATUS_NOT_PPK = 'rgb(215, 88, 70)';
export const COLOR_STATUS_PPK = 'rgb(102, 100, 233)';
export const COLOR_STATUS_READY_TO_BE_REVIEWED = 'rgb(140, 247, 109)';
export const COLOR_STATUS_ON_REVIEW = 'rgb(117, 234, 247)';
export const COLOR_STATUS_HAS_BEEN_REVIEWED = 'rgb(131, 76, 167)';
export const COLOR_STATUS_DONE = 'rgb(127, 138, 169)';
export const COLOR_STATUS_ACCENT = '#EC9821';
export const COLOR_STATUS_FINISHED = '#42A546';

export const COLOR_HORIZONTAL_LINE = '#EEEEEE';

export const COLOR_TEXT_GREY = '#989898';
export const COLOR_BODY_TEXT = '#2B3336';
export const COLOR_DANGEROUS = '#E30613';

export const PRINT_TYPE_INVOICE = 'INVOICE';
export const PRINT_TYPE_KMT = 'KMT';
export const PRINT_TYPE_SP2 = 'SP2';
export const PRINT_TYPE_PROFORMA_INVOICE = 'PROFORMA_INVOICE';
export const PRINT_TYPE_PREDEFINED_PROFORMA_INVOICE = 'PREDEFINED_PROFORMA_INVOICE';

export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SNACKBAR_ERROR = 'SNACKBAR_ERROR';

export const TRANSACTION_TYPE_TOPUP = 'TOPUP';
export const TRANSACTION_TYPE_PURCHASE = 'PURCHASE';
export const TRANSACTION_TYPE_INITIAL_BALANCE = 'INITIAL_BALANCE';
export const TRANSACTION_TYPE_REFUND = 'REFUND';

export const NOT_AVAILABLE = 'n/a';
export const VENDOR_ID_GTC = 'GTC';

export const TARIFF_SIMULATION_ACTION_NEXT = 'NEXT';
export const TARIFF_SIMULATION_ACTION_EXTEND = 'EXTEND';

export const MONEY_LOAN_STATUS_NOT_APPLICABLE = 'NotApplicable';
export const MONEY_LOAN_STATUS_PENDING = 'Pending';
export const MONEY_LOAN_STATUS_CLEAR = 'Clear';

export const NOTIF_TITLE_PAYMENT = 'PAYMENT';
export const NOTIF_TITLE_MONEY_LOAN = 'MONEY LOAN';
export const NOTIF_TITLE_TOPUP = 'TOPUP';

export const TRACKING_STATUS_BEHANDLE_STATUS_WAITING_LIST = 'Waiting List';
export const TRACKING_STATUS_BEHANDLE_STATUS_NOT_PPK = 'Belum PPK';
export const TRACKING_STATUS_BEHANDLE_STATUS_PPK = 'PPK';
export const TRACKING_STATUS_BEHANDLE_STATUS_READY_TO_BE_REVIEWED = 'Siap Periksa';
export const TRACKING_STATUS_BEHANDLE_STATUS_ON_REVIEW = 'Sedang Periksa';
export const TRACKING_STATUS_BEHANDLE_STATUS_HAS_BEEN_REVIEWED = 'Selesai Periksa';
export const TRACKING_STATUS_BEHANDLE_STATUS_DONE = 'Done';

export const BL_TYPES = [
  { label: 'Master BL', value: 'Master BL' },
  { label: 'House BL', value: 'Host BL' },
];

export const FILE_EXTENSION_PDF = '.pdf';
export const FILE_EXTENSION_CSV = '.csv';
export const FILE_EXTENSION_IMAGE = 'image/*';
export const FILE_EXTENSION_SPREADSHEET = '.xlsx,.xls';
export const FILE_EXTENSION_SLIDESHOW = '.ppt, .pptx';
export const FILE_EXTENSION_DOCUMENT = '.doc,.docx';
export const FILE_EXTENSION_ZIP = '.zip,.rar,.7zip';
export const FILE_EXTENSION_ALL = 'image/*,.pdf,.xlsx,.xls,.ppt, .pptx,.doc,.docx,.txt,.zip,.rar,.7zip';

export const FIELD_TYPE_FILE = 'File';
export const FIELD_TYPE_TEXT = 'Text';
export const FIELD_TYPE_NUMBER = 'Number';
export const FIELD_TYPE_DATE = 'Date';
export const FORM_RESPONSE_STATUS_ON_PROCESS_VALIDATION = 'OnProcessValidation';
export const FORM_RESPONSE_STATUS_ON_PROCESS_PROFORMA = 'OnProcessProforma';
export const FORM_RESPONSE_STATUS_WAITING_PAYMENT = 'Waiting Payment';
export const FORM_RESPONSE_STATUS_FINISHED = 'Finished';
export const FORM_RESPONSE_STATUS_REJECTED = 'Rejected';
export const FORM_RESPONSE_STATUS_PROCESSING = 'Processing';
export const FORM_RESPONSE_STATUS_WAITING_APPROVAL = 'Waiting Approval';

export const FORM_RESPONSE_STATUSES = [
  { label: 'On Process Validation', value: FORM_RESPONSE_STATUS_ON_PROCESS_VALIDATION },
  { label: 'On Process Proforma', value: FORM_RESPONSE_STATUS_ON_PROCESS_PROFORMA },
  { label: 'Waiting Payment', value: FORM_RESPONSE_STATUS_WAITING_PAYMENT },
  { label: 'Waiting Approval', value: FORM_RESPONSE_STATUS_WAITING_APPROVAL },
  { label: 'Finished', value: FORM_RESPONSE_STATUS_FINISHED },
  { label: 'Rejected', value: FORM_RESPONSE_STATUS_REJECTED },
  { label: 'Processing', value: FORM_RESPONSE_STATUS_PROCESSING },
];

export const DEBOUNCED_SEARCH_DURATION = 700;

export const TABLE_FIELD_FORM_RESPONSE_NO = 'no';
export const TABLE_FIELD_FORM_RESPONSE_FORM_TITLE = 'formTitle';
export const TABLE_FIELD_FORM_RESPONSE_USER = 'user';
export const TABLE_FIELD_FORM_RESPONSE_SUBMIT_DATE = 'submitDate';
export const TABLE_FIELD_FORM_RESPONSE_ACTIONS = 'actions';
export const TABLE_FIELD_FORM_RESPONSE_STATUS = 'status';
export const TABLE_FIELD_FORM_RESPONSE_SUBMIT_ID = 'submtiId';

export const STATUS_ENABLED = 'Enabled';
export const STATUS_DISABLED = 'Disabled';
export const status = [{ label: STATUS_ENABLED, value: 'Enabled' }, { label: STATUS_DISABLED, value: 'Disabled' }];
export const BOOLEAN_OPTION = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];
export const TYPE_FILE = 'File';
export const TYPE_NUMBER = 'Number';
export const TYPE_TEXT = 'Text';
export const TYPE_DATE = 'Date';

export const type = [
  { label: TYPE_FILE, value: 'File' },
  { label: TYPE_NUMBER, value: 'Number' },
  { label: TYPE_TEXT, value: 'Text' },
  { label: TYPE_DATE, value: 'Date' },
];

export const ERR_TOKEN_SESSION_EXPIRED = 'token or session expired';

export const ERP_PAYMENT_LOG_STATUS_OPTION = [
  { label: 'Success', value: 'SUCCESS' },
  { label: 'Error', value: 'ERROR' },
];

export const MAX_NOTES_LENGTH = 255;
