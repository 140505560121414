import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'uuid/v4';
import _ from 'lodash';
import HistoryDetail from './history-detail.presentation';
import {
  addToShoppingCartAsync,
  setMyPaymentHistorySort,
  setMyPaymentHistoryPaging,
  setMyPaymentHistoryFilter,
  requestPrintAsync,
  showErrorSnackbar,
  setPdfViewerStatus,
  downloadInvoiceInfoAsync,
} from '../../redux/action';
import {
  TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER,
  TABLE_SORT_ASCENDING,
  TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER,
  TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO,
  TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT,
  PRINT_TYPE_KMT,
  PRINT_TYPE_SP2,
  TABLE_FIELD_MY_PAYMENT_HISTORY_NOTES,
} from '../../constant';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getSelectedBlInfo = state => _.values(state.myOrder)
  .find(item => item.proformaNo
    .toLowerCase() === state.uiMyPaymentHistory.tappedProformaNo.toLowerCase());

const getFilteredSelectedBlItem = (state) => {
  const selectedBl = getSelectedBlInfo(state);
  const selectedBlItems = (selectedBl && selectedBl.items) || [];
  const { filters, sortField, sortDirection } = state.uiMyPaymentHistory;

  return selectedBlItems.map(item => ({ ...item, notes: item.notes || '' })).sort((left, right) => {
    if (typeof left[sortField] === 'string') {
      if (sortDirection === TABLE_SORT_ASCENDING) {
        return left[sortField].localeCompare(right[sortField]);
      }
      return right[sortField].localeCompare(left[sortField]);
    }

    if (sortDirection === TABLE_SORT_ASCENDING) {
      return left[sortField] - right[sortField];
    }
    return right[sortField] - left[sortField];
  })
    .filter(item => item.blNumber.toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_BL_NUMBER].toLowerCase()) >= 0)
    .filter(item => item.service.toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_SERVICE].toLowerCase()) >= 0)
    .filter(item => (item.invoiceNo || '-').toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_NUMBER].toLowerCase()) >= 0)
    .filter(item => (item.paymentOnBehalfOf || '-').toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_INVOICE_TO].toLowerCase()) >= 0)
    .filter(item => `${item.amount}`
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_AMOUNT].toLowerCase()) >= 0)
    .filter(item => item.notes.toLowerCase()
      .indexOf(filters[TABLE_FIELD_MY_PAYMENT_HISTORY_NOTES].toLowerCase()) >= 0);
};

const getProformaDetailTotalPage = (state) => {
  const selectedBlItems = getFilteredSelectedBlItem(state);
  const { pageSize } = state.uiMyPaymentHistory;
  const totalData = selectedBlItems && selectedBlItems ? selectedBlItems.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const mapStateToProps = state => ({
  selectedBl: getSelectedBlInfo(state),
  selectedBlItems: getFilteredSelectedBlItem(state),
  userCompany: state.currentUser.company,
  // proforma detail
  pageSize: state.uiMyPaymentHistory.pageSize,
  totalPage: getProformaDetailTotalPage(state),
  activePage: state.uiMyPaymentHistory.activePage,
  filters: state.uiMyPaymentHistory.filters,
  sortField: state.uiMyPaymentHistory.sortField,
  sortDirection: state.uiMyPaymentHistory.sortDirection,
  downloadingInvoiceInfo: state.uiMyPaymentHistory.downloadingInvoiceInfo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAddToCartPressed: async (termOrder) => {
    const itemId = uuid();
    const { blNumber, amount, service } = termOrder;
    await dispatch(addToShoppingCartAsync(itemId, blNumber, service, amount, termOrder));
  },

  // proforma detail actions
  onInvoicePressed: async (item) => {
    try {
      await dispatch(downloadInvoiceInfoAsync(item));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onKmtPressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_KMT,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onSp2Pressed: async (item) => {
    try {
      const data = {
        printType: PRINT_TYPE_SP2,
        invoiceNo: item.invoiceNo,
        blNo: item.blNumber,
        service: item.service,
      };
      await dispatch(requestPrintAsync(data));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setMyPaymentHistoryFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setMyPaymentHistoryPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setMyPaymentHistorySort(sortField, sortDirection));
  },
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(HistoryDetail));
