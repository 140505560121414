import React from 'react';
import classNames from 'classnames';
import {
  withStyles, Grid, Typography, Button,
} from '@material-ui/core';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import LocalizedString from '../../localization';
import * as Constant from '../../constant';
import { toMoment } from '../../helper';

const getItemSize = items => index => (items.length - 1 === index ? 444 : 452);

const styles = theme => ({
  btnPrimary: {
    ...Constant.DEFAULT_STYLES.WEB_USER.BUTTON_PRIMARY,
    width: '128px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  cardItem: {
    width: '100%',
    border: '1px solid #E5E5E5',
    borderRadius: '6px',
    boxSizing: 'border-box',
    padding: '16px',
    marginBottom: theme.spacing.unit,
    '&:last-child': {
      marginBottom: 0,
    },
    '&:after': {
      content: '',
      position: 'absolute',
      bottom: '0px',
      left: 0,
      width: '100%',
      height: '10px',
      backgroundColor: '#f4f4f4',
    },
  },
  cardItemActive: {
    backgroundColor: 'rgba(80, 171, 241, 0.2)',
  },
  spacer: {
    height: '8px',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  bodyText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
});

const isAddedToCart = (blNo, shoppingCart) => {
  if (shoppingCart) {
    return Boolean(shoppingCart.find(shopItem => shopItem.blNumber === blNo
      && shopItem.service === Constant.CART_SERVICE_NAME_IMPORT));
  }

  return false;
};

const isAddedToTerm = (blNo, termOrder) => {
  if (termOrder) {
    return termOrder.find(order => order.blNumber === blNo
      && order.service === Constant.CART_SERVICE_NAME_IMPORT);
  }

  return false;
};

const TariffSimulationButtons = ({
  classes,
  userCompany,
  item,
  shoppingCart,
  termOrder,
  onNextPressed,
  onExtendPressed,
  onOpenCartPressed,
  onOpenTermPressed,
}) => {
  const isInTerm = isAddedToTerm(item.blNo, termOrder);
  const isInCart = isAddedToCart(item.blNo, shoppingCart);
  const hasInvoice = !!item.invoiceNo;
  const hasGateIn = !!item.gateInCdp;
  const isSameCompanyAsNextActor = userCompany.id === item.createdByCompany;

  return (
    <div className={classes.buttonContainer}>
      {(hasInvoice && !isInCart) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnPrimary}
          onClick={() => onExtendPressed(item.blNo, item.validSp2Date, item.validDoDate)}
          disabled={!isSameCompanyAsNextActor}
        >
          {LocalizedString.tariffSimulation.btnExtend}
        </Button>
      )}
      {(isInTerm && !isInCart) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnPrimary}
          onClick={() => onOpenTermPressed(item.blNo, isInTerm)}
        >
          {LocalizedString.common.buttonCaptionOpenTerm}
        </Button>
      )}
      {isInCart && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnPrimary}
          onClick={() => onOpenCartPressed()}
        >
          {LocalizedString.common.buttonCaptionOpenCart}
        </Button>
      )}
      {(!isInTerm && !isInCart) && (
        <Button
          variant="contained"
          color="primary"
          className={classes.btnPrimary}
          disabled={hasInvoice || !hasGateIn}
          onClick={() => onNextPressed(item.blNo)}
        >
          {LocalizedString.common.buttonCaptionNext}
        </Button>
      )}
    </div>
  );
};

const TariffSimulationBlCard = ({
  classes,
  userCompany,
  bl,
  selectedBlNo,
  shoppingCart,
  termOrder,

  onNextPressed,
  onExtendPressed,
  onOpenCartPressed,
  onOpenTermPressed,
}) => (
  <>
    <AutoSizer>
      {({ width, height }) => (
        <List
          autoHeight
          className="List"
          height={height}
          itemCount={bl.length}
          itemSize={getItemSize(bl)}
          width={width}
        >
          {({ index, style }) => {
            const item = bl[index];
            return (
              <div style={style}>
                <div
                  key={item.blNo}
                  className={classNames(classes.cardItem, {
                    [classes.cardItemActive]: selectedBlNo && item.blNo === selectedBlNo,
                  })}
                >
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <Typography color="primary" variant="h6" className={classes.ellipsis}>
                        {`${LocalizedString.tariffSimulation.lblBlNo} ${item.blNo}`}
                      </Typography>
                      <Typography color="primary" variant="body1" className={classNames(classes.bodyText, classes.ellipsis)}>
                        {`${LocalizedString.tariffSimulation.lblAjuNo} ${item.ajuNo}`}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblInvoiceNo}</Typography>
                      <Typography className={classNames(classes.capitalize, classes.ellipsis)}>{item.invoiceNo || '-'}</Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblVesselVoyage}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.vessel}
                        &nbsp;-&nbsp;
                        {item.voyage}
                      </Typography>
                    </Grid>

                    <Grid item xs={8} md={8}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblGateInCdp}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.gateInCdp ? toMoment(item.gateInCdp).format(Constant.MOMENT_DATE_FORMAT_STRING) : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} md={8}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblValidSP2Date}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.validSp2Date ? toMoment(item.validSp2Date).format(Constant.MOMENT_DATE_FORMAT_STRING) : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblValidDODate}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.validDoDate ? toMoment(item.validDoDate).format(Constant.MOMENT_DATE_FORMAT_STRING) : '-'}
                      </Typography>
                    </Grid>

                    <Grid item xs={8} md={8}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblBillTo}</Typography>
                      <Typography className={classes.ellipsis}>
                        {item.billTo}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography variant="caption">{LocalizedString.tariffSimulation.lblPpjk}</Typography>
                      <Typography className={classNames(classes.capitalize, classes.ellipsis)}>{item.forwarderId || '-'}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TariffSimulationButtons
                        userCompany={userCompany}
                        classes={classes}
                        item={item}
                        shoppingCart={shoppingCart}
                        termOrder={termOrder}
                        onNextPressed={onNextPressed}
                        onExtendPressed={onExtendPressed}
                        onOpenTermPressed={onOpenTermPressed}
                        onOpenCartPressed={onOpenCartPressed}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className={classNames({
                  [classes.spacer]: index !== bl.length - 1,
                })}
                />
              </div>
            );
          }}
        </List>
      )}
    </AutoSizer>
  </>
);

export default withStyles(styles)(TariffSimulationBlCard);
