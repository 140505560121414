import { connect } from 'react-redux';
import momentTz from 'moment-timezone';
import moment from 'moment';
import _ from 'lodash';
import TariffSimulationBaseScreen from './tariff-simulation.presentation';
import * as Constant from '../../constant';
import {
  setTariffSimulationPasswordTextInput,
  setTariffSimulationPasswordInputDialog,
  downloadTariffSimulationOrderAsync,
  clearUIError,
  addToShoppingCartTariffSimulationAsync,
  addToTermOrderImportAsync,
  setTariffSimulationSearchText,
  setChosenTariffSimulationDate,
  setDialogCalendarStatus,
  setTariffSimulationFilter,
  setTariffSimulationPaging,
  setTariffSimulationSort,
  downloadTariffSimulationAsync,
  addTariffSimulation,
  downloadMyPaymentTermOrderAsync,
  downloadCustomerCompanyListAsync,
  downloadPpjkCompanyListAsync,
  setSelectedTariffSimulationPaymentBehalfOf,
  showErrorSnackbar,
  setTappedContainerTrackingBlNo,
  downloadingTariffSimulationProformaInvoice,
  downloadProformaInvoiceAsync,
  setPdfViewerStatus,
} from '../../redux/action';
import LocalizedString from '../../localization';
import { unauthorizedErrorHandler } from '../../helper';

const getpaymentBehalfOfList = (state) => {
  const { tappedBlNo, combineWithImportRelease } = state.uiTariffSimulation;

  if (tappedBlNo) {
    const { tariffSimulationOrder, importReleaseOrder } = state;
    const { role } = state.currentUser.company;

    const found = Object.values(tariffSimulationOrder).find(x => x.blNo === tappedBlNo)
      || Object.values(importReleaseOrder).find(x => x.blNo === tappedBlNo);
    if (found) {
      const {
        billTo,
        billToName,
        forwarderId,
        forwarderIdName,
      } = found;
      const retval = billTo ? [{ label: billToName, value: billTo }] : [];
      const lstPaymentBehalfOf = forwarderId && role.toUpperCase() === Constant.ROLE_PPJK
        ? [...retval, { label: forwarderIdName, value: forwarderId }]
        : [...retval];
      // return lstPaymentBehalfOf.map(item => ({ label: item, value: item }));
      return lstPaymentBehalfOf;
    }
  }

  return [];
};


const getFilteredTariffSimulation = (state) => {
  const { tariffSimulation } = state;
  const {
    filters, sortField, sortDirection,
  } = state.uiTariffSimulation;

  if (tariffSimulation && tariffSimulation.items) {
    const items = tariffSimulation.items.map((item, index) => ({ ...item, index }))
      .sort((left, right) => {
        if (typeof left[sortField] === 'string') {
          if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
            return left[sortField].localeCompare(right[sortField]);
          }
          return right[sortField].localeCompare(left[sortField]);
        }

        if (sortDirection === Constant.TABLE_SORT_ASCENDING) {
          return left[sortField] - right[sortField];
        }
        return right[sortField] - left[sortField];
      })
      .filter(item => item.description.toUpperCase().indexOf(
        filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_DESCRIPTION].toUpperCase(),
      ) >= 0)
      .filter((item) => {
        if (filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS] !== '') {
          return item.days === parseInt(filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_DAYS], 10);
        }
        return true;
      })
      .filter((item) => {
        if (filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE] !== '') {
          return item.price === parseInt(filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_PRICE], 10);
        }
        return true;
      })
      .filter((item) => {
        if (filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL] !== '') {
          return item.total === parseInt(filters[Constant.TABLE_FIELD_TARIFF_SIMULATION_TOTAL], 10);
        }
        return true;
      });

    return { ...tariffSimulation, items };
  }

  return undefined;
};

const getPageFilteredTariffSimulation = (state) => {
  const {
    activePage, pageSize,
  } = state.uiTariffSimulation;
  const filteredTariff = getFilteredTariffSimulation(state);

  if (filteredTariff) {
    const refinedActivePage = activePage || 1;
    const start = Math.min((refinedActivePage - 1) * pageSize, filteredTariff.items.length);
    const end = Math.min(start + pageSize, filteredTariff.items.length);
    return filteredTariff.items.slice(start, end);
  }

  return undefined;
};

const getTotalPage = (state) => {
  const tariffSimulationInfo = getFilteredTariffSimulation(state);
  const { pageSize } = state.uiTariffSimulation;
  const totalData = tariffSimulationInfo ? tariffSimulationInfo.items.length : 0;

  return Math.max(Math.ceil(totalData / pageSize), 1);
};

const getTariffSimulationInfo = (state) => {
  const { tariffSimulation, tariffSimulationOrder, importReleaseOrder } = state;
  const { blNo } = tariffSimulation;
  const orderItem = tariffSimulationOrder[blNo] || importReleaseOrder[blNo];
  const billToPaymentMethod = orderItem ? orderItem.billToPaymentMethod : '';
  const allowPpjkToUseDeposit = orderItem ? orderItem.allowPpjkToUseDeposit : '';
  return { ...tariffSimulation, billToPaymentMethod, allowPpjkToUseDeposit };
};

const getMinDate = (state) => {
  const { tariffSimulationOrder, importReleaseOrder } = state;
  const { tappedBlNo, action } = state.uiTariffSimulation;

  if (action === Constant.TARIFF_SIMULATION_ACTION_NEXT) {
    return new Date();
  }

  const selectedOrder = Object.values(tariffSimulationOrder).find(item => item.blNo === tappedBlNo)
    || Object.values(importReleaseOrder).find(item => item.blNo === tappedBlNo);

  return selectedOrder && selectedOrder.validSp2Date
    ? moment(selectedOrder.validSp2Date, Constant.MOMENT_DATE_API_FORMAT_STRING).add(1, 'days').toDate()
    : new Date();
};

const getMaxDate = (state) => {
  const { tariffSimulationOrder, importReleaseOrder } = state;
  const { tappedBlNo, action } = state.uiTariffSimulation;

  const selectedOrder = Object.values(tariffSimulationOrder).find(item => item.blNo === tappedBlNo)
    || Object.values(importReleaseOrder).find(item => item.blNo === tappedBlNo);

  if (action === Constant.TARIFF_SIMULATION_ACTION_NEXT) {
    if (selectedOrder && selectedOrder.validDoDate) {
      return moment(selectedOrder.validDoDate, Constant.MOMENT_DATE_API_FORMAT_STRING).toDate();
    }
  } else if (selectedOrder && selectedOrder.validSp2Date) {
    return moment(selectedOrder.validDoDate, Constant.MOMENT_DATE_API_FORMAT_STRING).toDate();
  }
  return null;
};

const getAjuNo = (state) => {
  const { tariffSimulationOrder, importReleaseOrder } = state;
  const { tappedBlNo } = state.uiTariffSimulation;

  const selectedOrder = Object.values(tariffSimulationOrder).find(item => item.blNo === tappedBlNo)
    || Object.values(importReleaseOrder).find(item => item.blNo === tappedBlNo);

  if (selectedOrder && selectedOrder.ajuNo) {
    return selectedOrder.ajuNo;
  }
  return '';
};

const mapStateToProps = state => ({
  notificationOpen: state.uiWebUserMain.notificationDrawerOpen,
  userCompany: state.currentUser.company,
  // downloadingContainerTrackings: state.uiContainerTracking.downloadingContainerTrackingsData,
  downloadingTariffSimulation: state.uiTariffSimulation.downloadingTariffSimulationInfo,
  downloadingTariffSimulationOrder: state.uiTariffSimulation.downloadingTariffSimulationOrder
    || state.uiMyPaymentTerm.downloading,
  downloadingProformaInvoice: state.uiTariffSimulation.downloadingProformaInvoice,
  addingShoppingCartItem: state.uiShoppingCart.addingItem || state.uiMyPaymentTerm.addingItem,
  dialogCalendarStatus: state.uiTariffSimulation.dialogCalendarStatus,
  tariffSimulationInfo: getTariffSimulationInfo(state),
  tariffSimulationDetail: getPageFilteredTariffSimulation(state),
  selectedDate: state.uiTariffSimulation.chosenDate,
  searchBarText: state.uiTariffSimulation.searchBarText,
  selectedBlNo: state.uiTariffSimulation.tappedBlNo,
  shoppingCart: _.values(state.shoppingCart),
  termOrder: _.values(state.myTermOrder),
  paymentBehalfOfList: getpaymentBehalfOfList(state),
  // selectedPaymentBehalfOf: getSelectedPaymentOnBehalfOf(state),
  selectedPaymentBehalfOf: state.uiTariffSimulation.selectedPaymentBehalfOf,
  currentUser: state.currentUser,
  minDate: getMinDate(state),
  maxDate: getMaxDate(state),
  pageSize: state.uiTariffSimulation.pageSize,
  totalPage: getTotalPage(state),
  activePage: state.uiTariffSimulation.activePage,
  filters: state.uiTariffSimulation.filters,
  sortField: state.uiTariffSimulation.sortField,
  sortDirection: state.uiTariffSimulation.sortDirection,
  selectedAjuNo: getAjuNo(state),

  dialogPasswordInputStatus: state.uiTariffSimulation.passwordInputDialog,
  password: state.uiTariffSimulation.password,
  initialValues: { notes: '' },
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAppear: async (currCompanyRole) => {
    Promise.all([
      dispatch(downloadTariffSimulationOrderAsync()),
      dispatch(downloadMyPaymentTermOrderAsync()),
      currCompanyRole.toLowerCase() === Constant.ROLE_PPJK.toLowerCase()
        ? dispatch(downloadCustomerCompanyListAsync())
        : dispatch(downloadPpjkCompanyListAsync()),
    ])
      .catch((error) => {
        dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
        unauthorizedErrorHandler(error, ownProps.history.push);
      });
  },
  onAddToCartPressed: async (tariffSimulation) => {
    try {
      dispatch(clearUIError(Constant.RXSTATE_TARIFF_SIMULATION));
      await dispatch(addToShoppingCartTariffSimulationAsync(tariffSimulation));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onAddToTermPressed: () => {
    dispatch(setTariffSimulationPasswordInputDialog(true));
  },
  onChangePasswordText: (text) => {
    dispatch(setTariffSimulationPasswordTextInput(text));
  },
  onCancelPasswordPressed: () => {
    dispatch(setTariffSimulationPasswordInputDialog(false));
  },
  onSubmitPasswordPressed: async (password, item) => {
    try {
      await dispatch(addToTermOrderImportAsync(item,
        Constant.CART_SERVICE_NAME_IMPORT,
        password));
      dispatch(setTariffSimulationPasswordTextInput(''));
      dispatch(setTariffSimulationPasswordInputDialog(false));
      dispatch(addTariffSimulation({}));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeSearchBarText: (text) => {
    dispatch(setTariffSimulationSearchText(text));
  },
  onChangeDate: (date) => {
    dispatch(setChosenTariffSimulationDate(
      momentTz(date)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format(Constant.MOMENT_DATE_API_FORMAT_STRING),
    ));
  },
  onPaymentBehalfOfSelected: (value) => {
    dispatch(setSelectedTariffSimulationPaymentBehalfOf(value));
  },
  onCloseCalendarDialog: () => {
    dispatch(setDialogCalendarStatus(false));
  },
  onCalendarDialogAppear: (currentUser) => {
    const { company } = currentUser;
    const { role } = company;
    if (role.toUpperCase() === Constant.ROLE_CUSTOMER) {
      dispatch(setSelectedTariffSimulationPaymentBehalfOf(company.id));
    }
  },
  onNextPress: async (blNo, chosenDate, selectedPaymentBehalfOf, values) => {
    try {
      dispatch(setChosenTariffSimulationDate(chosenDate));
      await dispatch(downloadTariffSimulationAsync(blNo, chosenDate, selectedPaymentBehalfOf,
        values.notes));
      dispatch(setDialogCalendarStatus(false));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    }
  },
  onChangeFilter: (filterName, value) => {
    dispatch(setTariffSimulationFilter(filterName, value));
  },
  onChangePaging: (pageField, value) => {
    dispatch(setTariffSimulationPaging(pageField, value));
  },
  onChangeSort: (sortField, sortDirection) => {
    dispatch(setTariffSimulationSort(sortField, sortDirection));
  },
  onClearTappedBlNoPressed: () => {
    dispatch(setTappedContainerTrackingBlNo(''));
  },

  onPrintProformaInvoicePressed: (preInv) => {
    console.log(preInv);
    try {
      dispatch(downloadingTariffSimulationProformaInvoice(true));
      dispatch(downloadProformaInvoiceAsync(preInv));
      dispatch(setPdfViewerStatus(true));
    } catch (error) {
      dispatch(showErrorSnackbar(LocalizedString.common.alertTitleError, error.message));
      unauthorizedErrorHandler(error, ownProps.history.push);
    } finally {
      dispatch(downloadingTariffSimulationProformaInvoice(false));
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TariffSimulationBaseScreen);
