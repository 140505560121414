import moment from 'moment';
import _ from 'lodash';
import { addShoppingCartItem, addingShoppingCartItem } from '../simple-action';
import { uploadShoppingCartItem } from '../../../helper';
import * as Constant from '../../../constant';

const getUiKey = (myTermOrder, blNumber, serviceName) => {
  const foundOnTermOrder = Object.values(myTermOrder)
    .find(x => x.blNumber === blNumber && x.service === serviceName);
  const termOrderKey = foundOnTermOrder ? foundOnTermOrder.invoiceNo : '';
  const uiKey = termOrderKey || blNumber;
  return uiKey;
};

export default (itemId, blNumber, serviceName, amount, item) => async (dispatch, getState) => {
  const { token } = getState().authentication;
  const { shoppingCart, uiTariffSimulation, myTermOrder } = getState();
  const { selectedPaymentBehalfOf, chosenDate } = uiTariffSimulation;

  try {
    const uiKey = getUiKey(myTermOrder, blNumber, serviceName);
    dispatch(addingShoppingCartItem(true, uiKey));

    const importSelectedPaymentOnBehalfOf = selectedPaymentBehalfOf
      ? (selectedPaymentBehalfOf.value || selectedPaymentBehalfOf) : undefined;
    const importPaymentOnBehalfOf = item.paymentOnBehalfOf || importSelectedPaymentOnBehalfOf;
    const paymentOnBehalfOf = serviceName === Constant.CART_SERVICE_NAME_IMPORT
      ? importPaymentOnBehalfOf
      : undefined;

    const importDeliveryDate = item.info || chosenDate;

    const newItem = {
      itemId,
      blNumber,
      service: serviceName,
      amount,
      info: serviceName === Constant.CART_SERVICE_NAME_IMPORT
        ? importDeliveryDate
        : undefined,
      addedToCartOn: moment().format(),
      item,
      invoiceNo: item.invoiceNo,
      checkout: false,
      ...((item.pph === true || item.pph === false) ? {
        pph: item.pph,
        paymentOnBehalfOf: item.billTo,
        originalService: item.originalService,
      } : {
        paymentOnBehalfOf,
      }),
    };

    // cart to send to backend
    const items = _.values(shoppingCart)
      .filter(shopCartItem => shopCartItem.blNumber === blNumber);

    const currentCart = items.length > 0 ? {
      itemId: items[0].itemId,
      blNumber: items[0].blNumber,
      checked: false,
      items: [],
      amount: items[0].amount,
      invoiceNo: items[0].invoiceNo,
      originalService: items[0].originalService,
      paymentOnBehalfOf: items[0].paymentOnBehalfOf,
      pph: items[0].pph,
      service: items[0].service,
    } : {
      itemId,
      blNumber,
      checked: false,
      items: [],
      amount,
      invoiceNo: item.invoiceNo,
      originalService: item.originalService,
      paymentOnBehalfOf: (item.pph === true || item.pph === false)
        ? item.billTo : paymentOnBehalfOf,
      pph: item.pph,
      service: serviceName,
    };

    const newCart = { ...currentCart, items: [...items, newItem] };

    await uploadShoppingCartItem(newCart, token);
    dispatch(addShoppingCartItem({ ...newItem, notes: item.notes }));
  } finally {
    dispatch(addingShoppingCartItem(false));
  }
};
