import {
  removeAllCheckoutItem,
  removeShoppingCartItem,
  setUploadingPayment,
  addPaymentId,
  setCheckoutPasswordTextInput,
  updateDepositBalance,
  setPaymentResult,
  startPaymentProcess,
  // setDialogPaymentDokuStatus,
  setDialogPaymentCompleteStatus,
  setMyPaymentHistorySearchText,
  setMyPaymentSelectedTab,
} from '../simple-action';
import LocalizedString from '../../../localization';
import { submitOrder, toCurrency, openNewTab } from '../../../helper';
import * as Constant from '../../../constant';

const transformCheckoutItems = getState => Object.values(getState().shoppingCheckout)
  .reduce((arr, ckOut) => {
    const { notes, ...itemWithoutNotes } = ckOut.item;
    return [...arr, { ...ckOut, item: itemWithoutNotes }];
  }, []);

export default (password, useCustomerDeposit, navigateTo) => async (dispatch, getState) => {
  try {
    dispatch(startPaymentProcess(true));
    dispatch(setUploadingPayment(true)); // submittingPayment in mobile
    if (!password) {
      throw new Error(LocalizedString.checkout.errMsgEmptyPassword);
    }
    const { token } = getState().authentication;
    const { company } = getState().currentUser;
    const items = transformCheckoutItems(getState);

    const paymentChannelCode = company.staticVa ? getState().uiCheckout.selectedBank : null;

    const retval = await submitOrder(password, paymentChannelCode, items, token);
    const { paymentId, remainingBalance, proformaNo } = retval;

    // validatePin

    dispatch(addPaymentId(paymentId));
    dispatch(setCheckoutPasswordTextInput(''));

    const { shoppingCheckout } = getState();
    Object.values(shoppingCheckout).forEach((checkout) => {
      dispatch(removeShoppingCartItem(checkout.itemId));
    });

    if (company.paymentMethod === Constant.PAYMENT_TYPE_DEPOSIT || useCustomerDeposit) {
      if (company.paymentMethod === Constant.PAYMENT_TYPE_DEPOSIT && !useCustomerDeposit) {
        dispatch(updateDepositBalance(remainingBalance));
      }

      const totalAmount = Object.values(shoppingCheckout)
        .reduce((total, header) => total + header.amount, 0);

      const data = {
        paymentId,
        proformaNo,
        paymentChannel: Constant.PAYMENT_TYPE_DEPOSIT,
        status: Constant.PAYMENT_STATUS_SUCCESS,
        amount: toCurrency(totalAmount),
      };

      dispatch(setPaymentResult(data));
      dispatch(startPaymentProcess(false));
      dispatch(removeAllCheckoutItem());
      // NavigationService.resetTo(Constant.NAV_NAME_PAYMENT_RESULT);
      // show payment success dialog
      dispatch(setDialogPaymentCompleteStatus(true));
    } else {
      dispatch(removeAllCheckoutItem());
      // dispatch(setDialogPaymentDokuStatus(true));

      if (company.staticVa) {
        dispatch(setMyPaymentHistorySearchText(proformaNo));
        dispatch(setMyPaymentSelectedTab(2)); // history tab
        navigateTo(Constant.ROUTE_NAME_MY_PAYMENT);
      } else {
        openNewTab(Constant.URL_PAYMENT.replace(/\{id\}/, paymentId));
      }
    }

    dispatch(removeAllCheckoutItem());
  } finally {
    dispatch(setUploadingPayment(false));
  }
};
