import LocalizedStrings from 'react-localization';
import * as Constant from './constant';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'ALERT',
      alertTitleWarning: 'WARNING',
      alertTitleConfirmation: 'CONFIRMATION',

      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'CANCEL',
      buttonCaptionDetail: 'DETAIL',
      buttonCaptionYes: 'YES',
      buttonCaptionNo: 'NO',
      buttonCaptionSave: 'SAVE',
      buttonCaptionEdit: 'EDIT',
      buttonCaptionSubmit: 'SUBMIT',
      buttonCaptionNext: 'NEXT',
      buttonCaptionPrev: 'PREV',
      buttonCaptionBack: 'BACK',
      buttonCaptionAlreadyInCart: 'Already in Cart',
      buttonCaptionAddToCart: 'Add to Cart',
      buttonCaptionMore: 'More',
      buttonCaptionOpenTerm: 'Open Term',
      buttonCaptionOpenCart: 'Open Cart',
      buttonCaptionBackToLogin: 'Back to Login',
      buttonCaptionDelete: 'Delete',
      buttonCaptionOpen: 'OPEN',
      buttonCaptionDownloadCsv: 'Download as CSV',

      errMsgEmptyRequiredFields: 'Required fields cannot be empty',
      errMsgInvalidEmailFormat: 'The email address is invalid',
      errMsgInvalidPhoneNumberFormat: 'The phone number format is invalid',
      errMsgMinPasswordLength: `Minimum password length is ${Constant.PASSWORD_MIN_LENGTH} characters`,
      errMsgPasswordDoesNotMatch: 'The password does not match',
      errMsgPinDoesNotMatch: 'The PIN does not match',
      errMsgInvalidPinFormat: 'PIN must be numbers',
      errMsgCannotOpenUrl: 'Cannot open the URL',
      errMsgRequired: 'Required',

      menuAdministration: 'Admin',
      menuMasterForwarder: 'Master Forwarder',
      menuMasterTruck: 'Master Truck',
      menuMasterVendor: 'Master Vendor',
      menuUserManagement: 'User Management',
      menuOrderDetailCustomer: 'Order Detail (Customer)',
      menuOrderDetailVendor: 'Order Detail (Vendor)',
      menuOrderListCustomer: 'Order List (Customer)',
      menuOrderListExportIn: 'Order List (Export In)',
      menuOrderListFleet: 'Order List (Fleet)',

      menuAboutUs: 'About Us',
      menuContactUs: 'Contact Us',

      msgDeleteConfirmation: 'Are you sure want to delete this item(s)?',
      msgConfirmCloseWindow: 'Are you sure you want to close this page?',

      labelNotAvailable: 'not accepted',
      labelActions: 'Actions',
      labelCartEmpty: 'Your cart is empty',
      labelCartNotEmpty: 'You have {0} item(s)',
      labelNotificationHeader: 'Notifications',
      labelNotificationEmpty: 'No new notifications',
      labelIAgreeTnc: 'I agree with the terms and conditions',
      labelCreatedUser: 'Created User',
      labelCreatedDate: 'Created Date',
      labelMaxChar: `Maximum length is ${Constant.MAX_NOTES_LENGTH} characters`,

      labelPagingPrevButton: 'Prev',
      labelPagingNextButton: 'Next',
      labelPagingPage: 'Page',
      labelPagingPageOf: 'of',

      labelRows: 'Rows',

      labelSuccess: 'Success',
      labelNoAccess: 'No Access',

      sessionExpired: 'Session expired. Please relogin',
      tempSymbol: '°C',

      footer: '© 2020 Cikarang Dry Port, Web Admin',

      placeholderSearch: 'Search...',
    },
    assign: {
      title: 'Assign',
      caption: 'Main menu / assign',

      labelTruckerCheckbox: 'Trucker ?',
      labelBulkAssign: 'Assign Multiple Clearance',

      buttonCaptionAssignBl: 'Clearance',
      buttonCaptionAssigned: 'Clearance',
      buttonCaptionAssignTruck: 'Assign Truck',
      buttonCaptionView: 'View',
      buttonCaptionAddContainer: 'Add Container',

      labelService: 'Service',
      labelInvoiceNo: 'Invoice No',
      labelBlNumber: 'BL No.',
      labelDoNo: 'DO No.',
      labelConsignee: 'Consignee',
      labelDocumentClearance: 'Document/Clearance',
      labelOrderType: 'Order Type',
      labelPpjkClearance: 'PPJK Clearance',
      labelPpjk: 'PPJK',
      labelStatus: 'Status',
      labelTrucker: 'Trucker',
      labelTruckNo: 'Truck No',
      labelCtrSize: 'Container Size',
      labelCtrType: 'Container Type',
      labelContainerNo: 'Container No',
      labelContainerList: 'Container List',
      labelAssignTo: 'Assign To',
      labelVendor: 'Vendor',
      labelCustomer: 'Customer',
      labelSoNo: 'So No',
      labelSealNo: 'Seal No',
    },
    bookExport: {
      fieldAta: 'Ata',
      fieldDoNo: 'DO No',
      fieldDoDate: 'DO Create Date',
      fieldEtd: 'Etd',
      fieldPeb: 'PEB Number',
      fieldPebDate: 'PEB Date',
      fieldDGClass: 'DG Class',
      fieldDGSubClass: 'DG Sub Class',
      fieldPoDestination: 'PO Destination',
      fieldPoTransit: 'PO Transit',
      fieldShippingLine: 'Shipping Line',
      fieldShippingLineBruto: 'Gross Weight in Kg',
      fieldShippingLineVolKemas: 'Volume in m3',
      fieldShippingLineJenisKemas: 'Package Type',
      fieldShippingLineJmlKemas: 'Containerized Amount',
      fieldShippingLineMerk: 'Brand',
      fieldShippingLineHscMerk: 'HSC Code',
      fieldShippingLineUraianKemas: 'Description of Goods',
      fieldShippingLineConsignee: 'Consignee',
      fieldShippingLineConsigneeAddr: 'Consignee Address',
      fieldVessel: 'Vessel',
      fieldVesselId: 'Vessel ID',
      fieldVesselOwner: 'Vessel Owner',
      fieldVoyage: 'Voyage',
      fieldVoyageTo: 'To',
      fieldVoyageToClosingDate: 'To Closing Date',
      fieldPaymentOnBehalfOf: 'Invoice on Behalf Of',
      fieldBillTo: 'Bill To',
      fieldForwarder: 'Forwarder',

      fieldPartyVol: 'Qty',
      fieldPartySize: 'Size',
      fieldPartyType: 'Type',

      labelTotalPrice: 'Payment Expected',
      labelAdministrasi: 'Administration',
      labelTaxBasis: 'Tax Basis/DPP',
      labelVatPpn: 'FAT/PPN',

      errorEmptyParty: 'Please enter at least 1 party',

      labelTabFeeder: 'KMT',
      labelTabParty: 'Party',
      labelTabPrice: 'Price',
    },
    cart: {
      title: 'Cart',
      caption: 'Main menu / cart',

      btnCheckout: 'Checkout',

      labelSelectAll: 'Pilih semua',
      labelBlNumber: 'BL Number',
      labelPaymentExpect: 'Amount',
      labelStatus: 'Confirmed',
      labelTotalPayment: 'Total Payment',
      msgEnterPinAllowPPJKTrue: 'This transaction will be charged on your customer\'s deposit. Please enter PIN for',
    },
    checkout: {
      title: 'Checkout',
      caption: 'Main menu / cart / checkout',

      btnPay: 'Pay',
      btnGoToPaymentHistory: 'GO TO My Payment History',
      buttonCaptionMoneyLoan: 'Pay Later',
      buttonCaptionTermAndCondition: 'Terms and Conditions',

      errMsgEmptyPassword: 'Password cannot be empty',

      labelPaymentMethod: 'Payment Method',
      labelDeposit: 'Deposit',
      labelBalance: 'Your balance IDR ',
      labelPaymentDialogTitle: 'Please enter your login password',
      labelLoan: 'Money Loan',
      labelOutstandingBalance: 'Your outstanding balance',
      labelEnterPin: 'Enter PIN',
      labelPin: 'PIN',
      labelTermAndCondition: 'I agree to',
      labelTermAndCondition2: 'of MyCDP',

      dialogDirectPaymentTitle: 'Direct Payment',
      dialogTncTitle: 'Terms and Conditions',
    },
    dashboardUser: {
      labelAssign: 'Assign',
      labelContainerTracking: 'Container Tracking',
      labelExport: 'Export',
      labelFeederService: 'Trucking',
      labelImport: 'Import',
      labelTrackingStatusBehandle: 'Tracking Status Behandle',
      labelImportRelease: 'Import Release',
      labelMainMenu: 'Main Menu',
      labelMore: 'More',
      labelMyPayment: 'My Payment',
      labelSubmitRequest: 'Submit Request',
      labelReeferData: 'Reefer Data',
      labelMyAccount: 'My Account',
      labelContactUs: 'Contact Us',
      labelAboutUs: 'About Us',
      labelLess: 'Less',

      buttonViewProfile: 'View Profile',
    },
    export: {
      title: 'Export',
      caption: 'Main menu / export',

      labelBlNo: 'BL No.',
      labelCDPClosingTime: 'CDP Closing Time',
      labelDoNo: 'DO No',
      labelVesselVoyage: 'Vessel-Voyage',
      labelInvoiceNo: 'Invoice No.',
      labelShippingLineVesselVoyage: 'Shipping Line Vessel Voyage',

      labelCoRef: 'Co Ref',
      labelKmtNo: 'KMT No',
      labelKmtDate: 'KMT Date',
      labelBillTo: 'Bill To',
      labelForwarderId: 'Forwarder Id',

      labelContainerNumber: 'Container Number',
      labelContainerSize: 'Container Size',
      labelContainerType: 'Container Type',
      labelContainerStatus: 'Container Status',
      labelGateInCdp: 'Gate In CDP',
      labelGateOutCdp: 'Gate Out CDP',
      labelGateInTo: 'Gate In Sea Port',
      labelGross: 'Gross',
      labelLoadedToVessel: 'Loaded to Vessel',
      labelSoNo: 'SO No',
      labelSealNo: 'Seal No',
      labelStatusHaulage: 'Status Haulage',

      ntfBookingSuccess: 'Your booking has been sent',
    },
    aboutUs: {
      title: 'About Us',
      caption: 'Main menu / about us',
    },
    contactUs: {
      title: 'Contact Us',
      caption: 'Main menu / contact us',

      buttonSend: 'Send',

      labelOfficeLocation: 'Office Location',
      labelSubject: 'Subject',
      labelMessage: 'Message',

      phoneAlt: 'Phone',
      phoneNumber: '+62 21 2908 2908',

      messageAlt: 'Whatsapp',
      messageNumber: '+62 812 125 3000',
    },
    containerTracking: {
      title: 'Container Tracking',
      caption: 'Main menu / container tracking',

      captionSp2Import: 'SP2 Import',

      lblService: 'Service',
      lblContainerNumber: 'Container Number',
      lblContainerSize: 'Size',
      lblContainerGateIn: 'Gate In CDP',
      lblContainerParty: 'Party',
      lblContainerBcNoAndDate: 'BC No and Date',
      lblContainerBcPos: 'BC POS',
      lblContainerVesselName: 'Vessel Name',
      lblContainerVoyageNo: 'Voyage No',
      lblContainerType: 'Type',
      lblContainerSeaPort: 'Sea Port',
      lblContainerGateOutSeaPort: 'Gate Out Seaport',
      lblContainerGateInSeaport: 'Gate In Seaport',
      lblContainerGateInCDP: 'Gate In CDP',
      lblContainerGateOutCDP: 'Gate Out CDP',
      lblContainerPickUpByConsignee: 'Pick Up by Consignee',
      lblContainerDwellingTime: 'Dwelling Time',
      lblContainerShipper: 'Shipper',
      lblContainerExporter: 'Exporter',
      lblContainerEta: 'ETA',

      lblTitleContainerNo: 'Container No.',
    },
    depositHistory: {
      title: 'Deposit',
      caption: 'Main menu / profile / deposit',
      detailTitle: 'History Transaction',

      labelType: 'Type',
      labelDate: 'Date',
      labelAmount: 'Amount',
      labelInvoiceNo: 'Proforma No',
      labelBalance: 'Your Balance',
      labelOutstandingBalance: 'Outstanding Balance',
      labelMinimumBalance: 'Minimum Balance',
      labelVaNumber: 'Virtual Account No',
      labelTopUpInfo: '*You can topup your balance using the Virtual Account number above. Click the button below to show steps to do it',
      labelTypeTopUp: 'Top Up',
      labelTypePurchase: 'Purchase',
      labelTypeInitialBalance: 'Initial Balance',
      labelHistoryTransaction: 'History Transaction',
      labelMoneyLoanHistoryTransaction: 'Money Loan History Transaction',
      labelStatus: 'Status',
      labelTermDays: 'Term Days',
      labelTotalLoan: 'Total Loan',
      labelFilter: 'Filter',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',

      errMsgStartDate: 'Start Date must be before or equal to End Date',
      errMsgEndDate: 'End Date must be after or equal to Start Date',

      buttonCaptionTopUp: 'Top Up',
      buttonCaptionMoneyLoan: 'Pay Later',
      buttonCaptionCopy: 'Copy',
      buttonCaptionGuide: 'Guide step by step',
      buttonCaptionExpand: 'Expand',
      buttonCaptionCollapse: 'Collapse',
      buttonCaptionDownloadCsv: 'Download as CSV',
      buttonCaptionShow: 'Show',
    },
    bookingFeeder: {
      title: 'New Booking',

      labelBlNumber: 'BL No.',
      labelBillTo: 'Bill To',
      labelForwarder: 'Forwarder',
      labelPaymentOnBehalfOf: 'Invoice on Behalf',
      labelDoNo: 'DO No',
      labelDoExpired: 'DO Expired',
      labelEmptyDepo: 'Empty Depo',
      labelArea: 'Route',
      labelWarehouse: 'Warehouse',
      labelSpcDate: 'Last Freetime Date',
      labelPlanDate: 'Plan Date',

      fieldPartyVol: 'Qty',
      fieldPartySize: 'Size',
      fieldPartyType: 'Type',

      btnAddPhoto: 'Add Document',
    },
    loginScreen: {
      title: 'CDP',
      subTitle: 'Dashboard',
      footer: '© 2020 Cikarang Dry Port',
      buttonCaptionLogin: 'Sign In',
      buttonCaptionForgetPassword: 'Forget Password?',
      buttonCaptionRegister: 'Register',
      labelUsername: 'Username',
      labelPassword: 'Password',
      labelRememberMe: 'Remember Me',
      errEmptyUsernamePassword: 'Email and password cannot be empty',
      errNotAdmin: 'This user is not Admin',
    },
    forgetPasswordScreen: {
      title: 'Forget Password',
      lableEmail: 'Email',
      labelNewPassword: 'New Password',
      labelConfirmNewPassword: 'Retype New Password',
      description: 'Write your email here. We will send a PIN to your email for resetting your password',
    },
    registerPasswordScreen: {
      labelNewPassword: 'New Password',
      labelConfirmNewPassword: 'Retype New Password',
      labelPin: 'PIN',
      labelConfirmPin: 'Retype PIN',
    },
    masterMenu: {
      labelHistoryContainerTracking: 'History Container Tracking',
      labelHistoryExport: 'History Export',
      labelHistoryImport: 'History Import',
      labelHistoryPayment: 'History Payment',
      labelHistoryTrucking: 'History Trucking',
      labelMasterUser: 'Master User',
      labelAdminPaymentHistory: 'Payment',
      labelForm: 'Form',
      labelField: 'Field',
      labelDashboard: 'Dashboard',
      labelResponse: 'Response',
      labelAdminErpPaymentLog: 'ERP Payment Log',
    },
    masterForwarder: {
      title: 'Master Forwarder',
      caption: 'Main menu > master forwarder',
      detailTitle: 'Forwarder Data',

      btnAddNew: 'Add Forwarder',
      labelForwarderId: 'Forwarder ID',
      labelForwarderName: 'Fwd Name',
      labelForwarderAddr1: 'Fwd Addr 1',
      labelNpwp: 'NPWP',
      labelCity: 'City',
      labelProvince: 'Province',
    },
    masterTruck: {
      title: 'Master Truck',
      caption: 'Main menu > master truck',
      detailTitle: 'Truck Data',

      btnAddNew: 'Add Truck',
      labelTruckNo: 'Truck No',
      labelRfid: 'RFID',
      labelPlateNumber: 'Plat No',
      labelVendor: 'Vendor',
      labelCid: 'CID',
      labelWeight: 'Weight',
    },
    masterVendor: {
      title: 'Master Vendor',
      caption: 'Main menu > master vendor',
      detailTitle: 'Vendor Data',

      btnAddNew: 'Add Vendor',
      labelId: 'ID',
      labelVendorId: 'Vendor Id',
      labelVendorName: 'Vendor Name',
    },
    masterUser: {
      title: 'Master User',
      caption: 'Master user CDP',
      detailTitle: 'User Data',
      editTite: 'Update User',

      btnAddNew: 'Add New User',

      labelIndex: '#',
      labelUsername: 'Username',
      labelEmail: 'Email',
      labelFullName: 'Full Name',
      labelCompanyName: 'Company Name',
      labelRegisteredOn: 'Registered On',
      labelIsAdmin: 'User Role',
      labelIsCorporateAdmin: 'Corporate Admin ?',
    },
    form: {
      title: 'Form',
      detailTitle: 'Form Data',
      editTite: 'Update Form',
      detailTite: 'Detail Form',

      btnAddNew: 'Add New Form',

      labelNo: 'No',
      labelTitle: 'Title',
      labelOrder: 'Order',
      labelStatus: 'Status',
      labelDescription: 'Description',

      btnCaptionSubmit: 'Create',
      btnCaptionSave: 'Update',
    },
    field: {
      title: 'Field',
      detailTitle: 'Field Data',
      editTite: 'Update Field',
      detailTite: 'Detail Field',

      btnAddNew: 'Add New Field',

      labelNo: 'No',
      labelName: 'Name',
      labelLabel: 'Label',
      labelOrder: 'Order',
      labelRequired: 'Required',
      labelType: 'Type',
      labelStatus: 'Status',
      labelForm: 'Form',

      btnCaptionSubmit: 'Create',
      btnCaptionSave: 'Update',
    },
    adminPaymentHistory: {
      labelDays: 'Days',
      labelDuration: 'Duration',
      title: 'Payment',
      caption: 'Master payment CDP',
      detailTitle: 'Payment Data',
      btnAddNew: 'Add New Payment',
      cardTitleCart: 'Shopping Cart',

      labelProformaNo: 'Proforma No',
      labelStatus: 'Status',
      labelMethod: 'Method',
      labelTotalAmount: 'Total Amount',
      labelItems: 'Items',
      labelPaidOn: 'Paid On',
      labelPaymentCode: 'Payment Code',
      labelPaymentCodeValidUntil: 'Payment Code Valid Until',
      labelChannel: 'Channel',
      labelCreatedBy: 'Created By',
      labelPaidBy: 'Paid By',
      labelBlNo: 'BL No',
      labelInvoiceNo: 'Invoice No',
      labelAmount: 'Amount',
      labelService: 'Service',
      labelInvoiceName: 'Invoice Name',
    },
    adminPaymentHistoryAdd: {
      title: 'Process Payment',
      labelSearch: 'Search',
      labelBlNo: 'BL No',
      labelInvoiceNo: 'Invoice No',
      labelService: 'Service',
      labelBillto: 'Bill To',
      labelPaymentOwner: 'Payment Owner',
    },
    myPayment: {
      title: 'My Payment',
      caption: 'Main menu > my payment',

      labelOutstanding: 'Outstanding',
      labelTerm: 'Term',
      labelHistory: 'History',
      labelOrder: 'Order',

      labelAlmostDue: 'Almost Due',
      labelPastDue: 'Past Due',

      msgInvoiceInfo: 'The Tax Invoice Serial Number obtained from CoreTax will be listed on the invoice H+2 from the invoice date. Please check regularly',
    },
    submitRequest: {
      documentSubmissionCaption: 'Main menu > submit request',
      newDocumentCaption: 'Document submission > new document',

      labelDocumentSubmission: 'Document Submission',
      labelNewDocument: 'New Document',
      labelSubmitId: 'Submit ID',
      labelSubmitDate: 'Submit Date',
      labelStatus: 'Status',
      labelDescription: 'Description',
      labelNoDocuments: 'No Document(s) Found',
      labelNoFields: 'No Field(s) Found',
      labelNoData: 'No Data Found',
      labelPaymentOnBehalf: 'Payment on Behalf',
      labelStatusNotes: 'Status Notes',
      labelForm: 'Form',
      labelUser: 'User',
      labelResponse: 'Response',
      labelBlNoRefNo: 'BL No/Ref No',

      uploadDataSuccess: 'Success upload response',
      downloadFileSuccess: 'Success download file',

      buttonCaptionSubmitNewDocument: 'Submit New Document',
      buttonCaptionDownload: 'Download',
      buttonCaptionViewPayment: 'VIEW PAYMENT',
      buttonCaptionViewTransaction: 'VIEW TRANSACTION',
    },
    myPaymentHistory: {
      captionInvoicePdf: 'Invoice',
      captionKmtPdf: 'KMT',
      captionSp2Pdf: 'SP2',
      captionCancelPayment: 'Cancel Payment',
      captionChangePayment: 'Change Payment',
      labelProformaNo: 'Proforma No',
      labelAmount: 'Amount',
      labelItem: 'Item',
      labelMethod: 'Method',
      labelChannel: 'Channel',
      labelVaNumber: 'VA Number',
      labelBlNumber: 'BL Number',
      labelService: 'Service',
      labelInvoiceNo: 'Invoice No',
      labelInvoiceTo: 'Invoice To',
      buttonCaptionPay: 'Pay',
      labelNotes: 'Notes',
    },
    myPaymentOutstanding: {
      labelProformaNo: 'Proforma No',
      labelStatus: 'Status',
      labelCreatedBy: 'Created By',
      labelCreatedOn: 'Created On',
      labelPaidOn: 'Paid On',
    },
    myPaymentTerm: {
      labelBlNo: 'BL No',
      labelInvoiceNo: 'Invoice No',
      labelDueDate: 'Due Date',
      labelTotalPrice: 'Amount',
      labelCreatedBy: 'Created By',
      labelCreatedOn: 'Created On',
      labelService: 'Service',
      labelInvoiceName: 'Invoice Name',
    },
    myPaymentOrder: {
      labelBlNo: 'BL No',
      labelService: 'Service',
      labelProformaNo: 'Proforma No.',
      labelInvoiceNo: 'Invoice No.',
      labelAmount: 'Amount',
      labelInvoiceName: 'Invoice Name',
      labelCustomerType: 'Customer Type',
      labelCreatedOn: 'Created On',
      labelReimbursementPph: 'Reimbursement PPH',

      buttonCaptionInvoicePDF: 'View Invoice',
      buttonCaptionViewProforma: 'View Proforma',
      buttonCaptionSP2: 'View SP2',
      buttonCaptionKMT: 'View KMT',
    },
    orderDetailCustomer: {
      title: 'Order Detail (Customer)',
      caption: 'home > order detail (customer)',
      labelContainerNo: 'Container No',
      labelSoNo: 'SO No',
      labelBlNo: 'BL No',
      labelContainerNoOut: 'Container No Out',
      labelSoNoOut: 'SO No Out',
      labelBlNoOut: 'BL No Out',
      labelStatus: 'Status',
      labelDriverName: 'Driver',
      labelGateInCdp: 'Gate In Cdp',
      labelGateOutCdp: 'Gate In Cdp',
      labelTruckNo: 'Truck No',
      labelTabAssignTruck: 'Assign Truck',
      labelTabAssigned: 'Assigned',
      labelTabDone: 'Done',
    },
    orderDetailVendor: {
      title: 'Order Detail (Vendor)',
      caption: 'home > order detail (vendor)',
      labelContainerNo: 'Container No',
      labelSoNo: 'SO No',
      labelBlNo: 'BL No',
      labelContainerNoOut: 'Container No Out',
      labelSoNoOut: 'SO No Out',
      labelBlNoOut: 'BL No Out',
      labelStatus: 'Status',
      labelDriverName: 'Driver',
      labelGateInCdp: 'Gate In Cdp',
      labelGateOutCdp: 'Gate In Cdp',
      labelTruckNo: 'Truck No',
      labelTabAssignTruck: 'Assign Truck',
      labelTabAssigned: 'Assigned',
      labelTabDone: 'Done',
    },
    orderListCustomer: {
      title: 'Orders',
      caption: 'home > orders',

      labelBillTo: 'Bill to',
      labelBlNo: 'BL No',
    },
    orderListExportIn: {
      title: 'Orders',
      caption: 'home > orders',

      labelBillTo: 'Bill to',
      labelBlNo: 'BL No',
    },
    registerEmailScreen: {
      title: 'Registration',
      labelEmail: 'Email',
      description: 'Write your email here. We will send a PIN to your email to continue the registration process',
    },
    pdfViewerScreen: {
      titleSP2: 'SP2',
      titleKMT: 'KMT',
      titleInvoice: 'Invoice',
      titleHowToTopUp: 'How To Top Up',
      titleProformaInvoice: 'Proforma Invoice',

      labelDownloading: 'Downloading',
    },
    pinVerificationScreen: {
      title: 'PIN Verification',
      subTitle: 'Please enter PIN code we just sent to your email',
      labelPin: 'PIN',
      expirationLabel: 'Your PIN will expire in',
      errMsgPinMustBeSixDigits: 'The PIN must be six digits',
    },
    registrationInfoScreen: {
      title: 'Registration Info',
      labelFullName: 'Full Name',
      labelPhone: 'Phone',
      labelJobTitle: 'Job Title',
      labelPassword: 'Password',
      labelConfirmPassword: 'Confirm Password',

      buttonCaptionRegister: 'REGISTER',
    },
    homeScreen: {
      title: 'Home',
    },
    priceDetail: {
      lblAdministration: 'Administrasi',
      lblTaxBasis: 'Tax Basis/DPP',
      lblTotalBeforeDiscount: 'Total sebelum diskon',
      lblDiscount: 'Diskon',
      lblFatPPN: 'FAT/PPN',

      lblDescription: 'Description',
      lblDays: 'Days',
      lblPrice: 'Price (IDR)',
    },
    profileScreen: {
      title: 'Profile',
      breadcrumb: 'Main menu / profile',
      dialogTitleSelectPicture: 'Select Picture',

      labelAddress: 'Address',
      labelCompany: 'Company',
      labelEmail: 'Email',
      labelFullName: 'Full Name',
      labelJobTitle: 'Job Title',
      labelPhoneNumber: 'Phone Number',
      labelUserRole: 'User Role',
      labelCompanyRole: 'Company Role',
      labelUsername: 'Username',

      labelOldPassword: 'Old Password',
      labelNewPassword: 'New Password',
      labelConfirmNewPassword: 'Retype New Password',

      labelOldPin: 'Old Pin',
      labelNewPin: 'New Pin',
      labelConfirmNewPin: 'Retype New Pin',

      buttonCaptionChangePassword: 'Change Password',
      buttonCaptionChangePin: 'Change PIN',
    },
    reeferData: {
      title: 'Reefer Data',
      caption: 'Main menu / more / reefer data',

      lblContainerNumber: 'Container Number',
      lblContainerSize: 'Size',
      lblContainerShiftDate: 'Date',
      lblContainerParty: 'Party',
    },
    reeferDataDetail: {
      lblContainerNumber: 'Container No.',
      lblShiftId: 'Shift ID',
      lblShiftDate: 'Date',
      lblSettingPointTemp: 'Set. Temp',
      lblSupplyAirTemp: 'Supply Air',
    },
    tariffSimulation: {
      title: 'Import',
      caption: 'Main menu / import',
      dialogTitleDelivery: 'Paid Thru',

      btnAlreadyInCart: 'Already In Cart',
      btnAddToCart: 'Add to Cart',
      btnPrintProformaInvoice: 'Print',

      btnAlreadyInTerm: 'Already In Term',
      btnAddToTerm: 'Add to Term',

      btnExtend: 'Extend',

      lblAdministration: 'Administrasi',
      lblBlNo: 'BL No.',
      lblDays: 'Days',
      lblDescription: 'Description',
      lblDiscount: 'Diskon',
      lblFatPPN: 'FAT/PPN',
      lblGateInCdp: 'Gate in CDP',
      lblParty: 'Party',
      lblPrice: 'Price (IDR)',
      lblSP2Expectation: 'SP2 Expectation',
      lblService: 'Service',
      lblSubprice: 'Subprice',
      lblTaxBasis: 'Tax Basis/DPP',
      lblTotalBeforeDiscount: 'Total sebelum diskon',
      lblVesselVoyage: 'Vessel-Voyage',
      lblPaymentBehalfOf: 'Invoice on Behalf Of',
      lblPpjk: 'PPJK',
      lblInvoiceNo: 'Invoice No',
      lblValidSP2Date: 'Valid SP2 Date',
      lblValidDODate: 'Valid DO Date',
      lblBillTo: 'Bill To',
      lblAjuNo: 'Nomor Aju:',
      lblNotes: 'Notes/Remark',
    },
    tariffSimulationRelease: {
      title: 'Import Release',
      caption: 'Main menu / import release',
      dialogTitleDelivery: 'Paid Thru',

      btnAlreadyInCart: 'Already In Cart',
      btnAddToCart: 'Add to Cart',
      btnPrintProformaInvoice: 'Print',
      btnSearch: 'Search BL',

      btnAlreadyInTerm: 'Already In Term',
      btnAddToTerm: 'Add to Term',

      btnExtend: 'Extend',
      btnNext: 'Next',

      lblAdministration: 'Administrasi',
      lblBlNo: 'BL No.',
      lblDays: 'Days',
      lblDescription: 'Description',
      lblDiscount: 'Diskon',
      lblFatPPN: 'FAT/PPN',
      lblGateInCdp: 'Gate in CDP',
      lblParty: 'Party',
      lblPrice: 'Price (IDR)',
      lblSP2Expectation: 'SP2 Expectation',
      lblService: 'Service',
      lblSubprice: 'Subprice',
      lblTaxBasis: 'Tax Basis/DPP',
      lblTotalBeforeDiscount: 'Total sebelum diskon',
      lblVesselVoyage: 'Vessel-Voyage',
      lblPaymentBehalfOf: 'Invoice on Behalf Of',
      lblPpjk: 'PPJK',
      lblInvoiceNo: 'Invoice No',
      lblValidSP2Date: 'Valid SP2 Date',
      lblValidDODate: 'Valid DO Date',
      lblBillTo: 'Bill To',
    },
    trucking: {
      title: 'Trucking',
      caption: 'Main menu / trucking',

      btnAddToCart: 'Add to Cart',
      btnAlreadyInCart: 'Already In Cart',
      btnNewBooking: 'Add New Booking',
      btnMore: 'More',

      lblAdministration: 'Administration',
      lblTaxBasis: 'Tax Basis/DPP',
      lblFATPPN: 'FAT/PPN',
      lblTotalPrice: 'Amount',
      lblBlNo: 'BL No.',
      lblESil: 'Order Number',
      lblStatusHaulage: 'Status Haulage',
      lblSoNo: 'So No.',
      lblContainerNumber: 'Container Number',
      lblContainerBlNumber: 'BL Number',
      lblContainerSoNumber: 'SO Number',
      lblContainerTransportStatus: 'Transport Status',
      lblService: 'Service',
      lblBookingStatus: 'Status',
      lblInvoiceNo: 'Invoice No.',
      lblPlanDate: 'Plan Date',
      lblParty: 'Party',
      lblNotes: 'Notes',

      lblContainerSealNo: 'Seal No',
      lblContainerSize: 'Container Size',
      lblContainerType: 'Container Type',
      lblContainerStatus: 'Container Status',
      lblDriverName: 'Driver Name',
      lblPhoneNumber: 'No HP',
      lblPlateNumber: 'No Police',

      lblGateInCdp: 'Gate In CDP',
      lblGateOutDelivery: 'Gate Out Delivery',
      lblGateInWarehouse: 'Gate In Warehouse',
      lblGateOutWarehouse: 'Gate Out Warehouse',
      lblGateInCdpMty: 'Gate In CDP Mty',
      lblGateOutMty: 'Gate Out CDP Mty',
      lblGateInCdpDepo: 'Gate In CDP Depo',
      lblGateOutCdpDepo: 'Gate Out CDP Depo',

      lblTitleContainer: 'Container',
      lblTitleDriver: 'Driver',
      lblTitlePort: 'Port',

      ntfBookingSuccess: 'Your booking has been sent',
      dialogTitleAddToCart: 'Please enter your notes below',
    },
    trackingStatusBehandle: {
      title: 'Tracking Status Behandle',
      caption: 'Main menu > Tracking Status Behandle',

      placeholderSearch: 'Search',

      labelOnProgress: 'On Progress',
      labelHistory: 'History',
      labelContainerNo: 'Container No',
      labelCustomer: 'Customer',
      labelStatus: 'Status',
      labelPibNo: 'PIB No',
      labelPibDate: 'PIB Date',
      labelGateInDate: 'Gate In CY Date',
      labelPendokDate: 'Pendok Date',
      labelPpkDate: 'PPK Date',
      labelRelocationDate: 'Relocation Date',
      labelPkbDate: 'PKB Date',
      labelReturnCyDate: 'Return CY Date',
      labelBhdLocation: 'BHD Location',
      labelCyLocation: 'CY Location',
      labelOpenSealDate: 'Open Seal Date',
      labelCloseSealDate: 'Close Seal Date',
      labelBlNo: 'BL No',
      labelGateOutDelivery: 'Gate Out Delivery',
      labelNamaPemeriksa: 'Nama Pemeriksa',
      labelPhoneNumber: 'Nomor Telepon Pemeriksa',
    },
    tariffSimulationReleaseSearchBase: {
      labelSPPBNo: 'SPPB No',
      labelSPPBDate: 'SPPB Date',

      placeholderSearchBlNo: 'Search BL No',
      placeholderSelectBlType: 'Select BL Type',
      placeholderMasterBL: 'Master BL Number',
    },
    response: {
      title: 'Response',

      labelNo: 'No',
      labelFormTitle: 'Form Title',
      labelUser: 'User',
      labelSubmitDate: 'Submit Date',
      labelStatus: 'Status',
      labelFormId: 'Form ID',
      labelFormName: 'Form Name',
      labelUserId: 'User ID',
      labelUserName: 'User Name',
      labelStatusNotes: 'Status Notes',
      labelFilter: 'Filter',
      labelActions: 'Actions',
      labelReset: 'Reset',
      labelSubmitId: 'Submit ID',
      labelPaymentOnBehalfId: 'Payment On Behalf ID',
      labelPaymentOnBehalfName: 'Payment On Behalf Name',
      labelResponse: 'Response',
    },
    erpPaymentLog: {
      title: 'ERP Payment Log',

      labelFilter: 'Filter',
      labelProformaNo: 'Proforma No',
      labelCompany: 'Company',
      labelAmount: 'Amount',
      labelStatus: 'Status',
      labelStatusNotes: 'Status Notes',
      labelCreatedDate: 'Created Date',
      labelRetry: 'Retry',
      labelLastRetryOn: 'Last Retry On',
      labelPayload: 'Payload',
      labelResponse: 'Response',
      labelActions: 'Actions',
      labelReset: 'Reset',

      buttonCaptionRetry: 'Retry',

      msgConfirmRetryErpPayment: 'Are you sure you want to retry this payment?',
    },
  },
  in: {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'GALAT',
      alertTitleWarning: 'PERINGATAN',
      alertTitleConfirmation: 'KONFIRMASI',

      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'BATAL',
      buttonCaptionDetail: 'DETAIL',
      buttonCaptionYes: 'YA',
      buttonCaptionNo: 'TIDAK',
      buttonCaptionSave: 'SIMPAN',
      buttonCaptionEdit: 'UBAH',
      buttonCaptionSubmit: 'KIRIM',
      buttonCaptionNext: 'LANJUT',
      buttonCaptionPrev: 'SEBELUMNNYA',
      buttonCaptionBack: 'KEMBALI',
      buttonCaptionOpen: 'BUKA',
      buttonCaptionDownloadCsv: 'Unduh CSV',

      errMsgEmptyRequiredFields: 'Tidak boleh ada field yang kosong',
      errMsgInvalidEmailFormat: 'Format email yang Anda masukkan salah',
      errMsgInvalidPhoneNumberFormat: 'Format nomor telepon yang Anda masukkan salah',
      errMsgMinPasswordLength: `Minimal panjang password adalah ${Constant.PASSWORD_MIN_LENGTH} karakter`,
      errMsgPasswordDoesNotMatch: 'Kedua kata sandi tidak cocok',
      errMsgCannotOpenUrl: 'Tidak bisa membuka URL',
      errMsgRequired: 'Harus diisi',

      menuAdministration: 'Admin',
      menuMasterForwarder: 'Master Forwarder',
      menuMasterTruck: 'Master Truck',
      menuMasterVendor: 'Master Vendor',
      menuUserManagement: 'User Management',
      menuOrderDetailCustomer: 'Order Detail (Customer)',
      menuOrderDetailVendor: 'Order Detail (Vendor)',
      menuOrderListCustomer: 'Order List (Customer)',
      menuOrderListExportIn: 'Order List (Export In)',
      menuOrderListFleet: 'Order List (Fleet)',

      menuAboutUs: 'Tentang Kami',
      menuContactUs: 'Hubungi Kami',

      labelCartEmpty: 'Keranjang Anda kosong',
      labelNotificationHeader: 'Notifikasi',
      labelNotificationEmpty: 'Tidak ada notifikasi baru',
      labelIAgreeTnc: 'Saya setuju dengan syarat dan ketentuan',

      footer: '© 2020 Cikarang Dry Port, Web Admin',
    },
    checkout: {
      title: 'Checkout',
      caption: 'Menu utama / cart / checkout',

      btnPay: 'Bayar',
      btnGoToPaymentHistory: 'Ke Riwayat Pembayaran Saya',
      buttonCaptionMoneyLoan: 'Pinjaman Uang',
      buttonCaptionTermAndCondition: 'Syarat dan Ketentuan',

      errMsgEmptyPassword: 'Sandi tidak boleh kosong',

      labelPaymentMethod: 'Metode Pembayaran',
      labelDeposit: 'Deposit',
      labelBalance: 'Saldo IDR ',
      labelPaymentDialogTitle: 'Mohon masukan kata sandi Anda',
      labelLoan: 'Pinjaman Uang',
      labelOutstandingBalance: 'Saldo terhutang anda',
      labelEnterPin: 'Masukan PIN',
      labelPin: 'PIN',
      labelTermAndCondition: 'Saya setuju dengan ',
      labelTermAndCondition2: ' dari MyCDP',

      dialogTncTitle: 'Syarat dan Ketentuan',
    },
    dashboardUser: {
      labelAssign: 'Assign',
      labelContainerTracking: 'Container Tracking',
      labelExport: 'Export',
      labelFeederService: 'Feeder Service',
      labelImport: 'Import',
      labelTrackingStatusBehandle: 'Tracking Status Behandle',
      labelMainMenu: 'Menu utama',
      labelMore: 'Lainnya',

      buttonViewProfile: 'Lihat Profil',
    },
    aboutUs: {
      title: 'Tentang Kami',
      caption: 'Menu utama / tentang kami',
    },
    contactUs: {
      title: 'Hubungi Kami',
      caption: 'Menu utama / hubungi kami',

      buttonSend: 'Kirim',

      labelOfficeLocation: 'Lokasi Kantor',
      labelSubject: 'Subjek',
      labelMessage: 'Pesan',

      phoneAlt: 'Telepon',
      phoneNumber: '+62 21 2908 2908',

      messageAlt: 'Whatsapp',
      messageNumber: '+62 812 125 3000',
    },
    containerTracking: {
      title: 'Lacak Kontainer',
      caption: 'Menu utama / lacak kontainer',
    },
    loginScreen: {
      title: 'CDP',
      subTitle: 'Admin Dashboard',
      footer: '© 2020 Cikarang Dry Port, Web Admin',
      buttonCaptionLogin: 'MASUK',
      buttonCaptionForgetPassword: 'Lupa Sandi',
      buttonCaptionRegister: 'Daftar',
      labelUsername: 'Nama Pengguna',
      labelPassword: 'Kata Sandi',
      labelRememberMe: 'Ingat Saya',
      errEmptyUsernamePassword: 'Email dan Sandi tidak boleh kosong',
      errNotAdmin: 'Akun ini bukan Admin',
    },
    myPayment: {
      title: 'Pembayaran Saya',
      caption: 'Menu utama > pembayaran saya',

      labelOutstanding: 'Outstanding',
      labelTerm: 'Term',
      labelHistory: 'Riwayat',
      labelOrder: 'Pesanan',

      labelAlmostDue: 'Hampir Jatuh Tempo',
      labelPastDue: 'Lewat Jatuh Tempo',

      msgInvoiceInfo: 'Nomor Seri Faktur Pajak yang diperoleh dari CoreTax akan tercantum pada invoice H+2 dari tanggal invoice. Mohon dicek secara berkala',
    },
    forgetPasswordScreen: {
      title: 'Lupa Sandi',
      lableEmail: 'Email',
      labelNewPassword: 'Kata Sandi Baru',
      labelConfirmNewPassword: 'Ulangi Kata Sandi Baru',
      description: 'Tulis email Anda di sini. Kami akan mengirim PIN ke email Anda yang bisa digunakan untuk mengembalikan password Anda',
    },
    registerEmailScreen: {
      title: 'Pendaftaran',
      labelEmail: 'Email',
      description: 'Tulis email Anda di sini. Kami akan mengirim PIN ke email Anda yang bisa digunakan untuk melanjutkan proses registrasi',
    },
    pinVerificationScreen: {
      title: 'Verifikasi PIN',
      subTitle: 'Masukkan PIN yang baru saja kami kirim ke email Anda',
      labelPin: 'PIN',
      expirationLabel: 'PIN akan kadaluarsa dalam',
      errMsgPinMustBeSixDigits: 'PIN harus terdiri dari 6 angka',
    },
    registrationInfoScreen: {
      title: 'Data Pendaftaran',
      labelFullName: 'Nama Lengkap',
      labelPhone: 'Telepon',
      labelJobTitle: 'Jabatan',
      labelPassword: 'Sandi',
      labelConfirmPassword: 'Ulangi Sandi',

      buttonCaptionRegister: 'DAFTAR',
    },
    homeScreen: {
      title: 'Halaman Utama',
    },
    profileScreen: {
      title: 'Profil',
      breadcrumb: 'Main menu / profil',

      labelAddress: 'Alamat',
      labelCompany: 'Perusahaan',
      labelRole: 'Peran',
      labelUsername: 'Nama Pengguna',
      labelFullName: 'Nama Lengkap',
      labelEmail: 'Email',
      labelPhoneNumber: 'Nomor Telepon',
      labelJobTitle: 'Posisi Jabatan',
      labelOldPassword: 'Kata Sandi Lama',
      labelNewPassword: 'Kata Sandi Baru',
      labelConfirmNewPassword: 'Ulangi Kata Sandi Baru',

      buttonCaptionChangePassword: 'Ubah Sandi',
    },
    trackingStatusBehandle: {
      title: 'Tracking Status Behandle',
      caption: 'Menu utama > Tracking Status Behandle',

      placeholderSearch: 'Cari',
      labelOnProgress: 'Dalam Proses',
      labelHistory: 'Riwayat',
      labelContainerNo: 'No Kontainer',
      labelCustomer: 'Pelanggan',
      labelStatus: 'Status',
      labelPibNo: 'No PIB',
      labelPibDate: 'Tanggal PIB',
      labelGateInDate: 'Tanggal CY Masuk',
      labelPendokDate: 'Tanggal Pendok',
      labelPpkDate: 'Tanggal PPK',
      labelRelocationDate: 'Tanggal Relokasi',
      labelPkbDate: 'Tanggal PKB',
      labelReturnCyDate: 'Tanggal CY Kembali',
      labelBhdLocation: 'Lokasi BHD',
      labelCyLocation: 'Lokasi CY',
      labelOpenSealDate: 'Tanggal Buka Segel',
      labelCloseSealDate: 'Tanggal Tutup Segel',
      labelBlNo: 'No BL',
      labelGateOutDelivery: 'Pengantaran Keluar',
      labelNamaPemeriksa: 'Nama Pemeriksa',
      labelPhoneNumber: 'Nomor Telepon Pemeriksa',
    },
    tariffSimulationReleaseSearchBase: {
      labelSPPBNo: 'SPPB No',
      labelSPPBDate: 'Tanggal SPPB',

      placeholderSearchBlNo: 'Cari Nomor BL',
      placeholderSelectBlType: 'Pilih Tipe BL',
      placeholderMasterBL: 'Ketik Master BL Disini',
    },
    response: {
      title: 'Respon',

      labelNo: 'No',
      labelFormTitle: 'Judul Form',
      labelUser: 'Pengguna',
      labelSubmitDate: 'Tanggal Kirim',
      labelStatus: 'Status',
      labelFormId: 'ID Form',
      labelFormName: 'Nama Form',
      labelUserId: 'ID Pengguna',
      labelUserName: 'Nama Pengguna',
      labelStatusNotes: 'Catatan Status',
      labelFilter: 'Filter',
      labelActions: 'Aksi',
      labelReset: 'Reset',
      labelSubmitId: 'ID Submit',
      labelPaymentOnBehalfId: 'ID Pembayaran Atas Nama',
      labelPaymentOnBehalfName: 'Nama Pembayaran Atas Nama',
      labelResponse: 'Respon',
    },
    erpPaymentLog: {
      title: 'ERP Payment Log',

      labelFilter: 'Filter',
      labelProformaNo: 'Nomor Proforma',
      labelCompany: 'Perusahaan',
      labelAmount: 'Jumlah',
      labelStatus: 'Status',
      labelStatusNotes: 'Catatan Status',
      labelCreatedDate: 'Tanggal Dibuat',
      labelRetry: 'Percobaan',
      labelLastRetryOn: 'Percobaan Terakhir Pada',
      labelPayload: 'Payload',
      labelResponse: 'Respon',
      labelActions: 'Aksi',
      labelReset: 'Reset',

      buttonCaptionRetry: 'Coba Lagi',

      msgConfirmRetryErpPayment: 'Apakah Anda yakin untuk mencoba kembali pembayaran ini?',
    },
  },
});

export default LocalizedString;
